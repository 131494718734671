import { Box, Card, IconButton, Stack, Typography, CircularProgress } from '@mui/material';
import { useState, useCallback, useEffect } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  DroppableProvided,
  DraggableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import Iconify from 'src/components/iconify';
import ImageEditDialog from './ImageEditDialog';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import {
  setCreateListingModeImages,
  addCreateListingModeImages,
  removeCreateListingModeImage,
  reorderCreateListingModeImages,
} from 'src/store/dashboard/slices/inventorySlice';
import {
  ImageItem,
  generateVideoThumbnail,
  checkVideoDuration,
  processInitialAssets,
  convertToAbsoluteUrl,
} from './utils/imageProcessingUtils';

export enum ImageUploadGridTypeEnum {
  CREATE = 'create',
  EDIT = 'edit',
}

interface ImageUploadGridProps {
  initialAssets?: Array<{ url: string; name: string; orderIndex: number }>;
  onImagesChange?: () => void;
  isLoading?: boolean;
  type: ImageUploadGridTypeEnum;
}

const MAX_IMAGE_SIZE = 7.5 * 1024 * 1024; // 7.5 MB in bytes
const MAX_VIDEO_SIZE = 50 * 1024 * 1024; // 50 MB in bytes

const sortByOrderIndex = (images: ImageItem[]) => {
  return [...images].sort((a, b) => {
    // If both are videos or both are images, sort by orderIndex
    if ((a.type === 'video' && b.type === 'video') || (a.type === 'image' && b.type === 'image')) {
      return a.orderIndex - b.orderIndex;
    }
    // If a is video and b is image, a should come after b
    if (a.type === 'video' && b.type === 'image') {
      return 1;
    }
    // If a is image and b is video, a should come before b
    if (a.type === 'image' && b.type === 'video') {
      return -1;
    }
    return 0;
  });
};

export default function ImageUploadGrid({
  initialAssets,
  onImagesChange,
  isLoading,
  type,
}: ImageUploadGridProps) {
  const dispatch = useDispatch();
  const images = useSelector((state: IRootState) => state.inventory.createListingModeImages);
  const [isDraggingExistingImage, setIsDraggingExistingImage] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [selectedImageToEdit, setSelectedImageToEdit] = useState<ImageItem | null>(null);
  const [hasLoadedInitialAssets, setHasLoadedInitialAssets] = useState(false);
  const [isProcessingInitialAssets, setIsProcessingInitialAssets] = useState(false);
  const [showAll, setShowAll] = useState(false);

  // Calculate visible images based on showAll state
  const visibleImages = sortByOrderIndex(showAll ? images : images.slice(0, 12));
  const hasMoreImages = images.length > 12;

  // Reset hasLoadedInitialAssets when initialAssets changes
  useEffect(() => {
    setHasLoadedInitialAssets(false);
  }, [initialAssets]);

  // Load initial assets when component mounts
  useEffect(() => {
    const loadInitialAssets = async () => {
      if (initialAssets && initialAssets.length > 0 && !hasLoadedInitialAssets) {
        setIsProcessingInitialAssets(true);
        const processedAssets = await processInitialAssets(initialAssets, hasLoadedInitialAssets);
        dispatch(setCreateListingModeImages(processedAssets));
        setHasLoadedInitialAssets(true);
        setIsProcessingInitialAssets(false);
      }
    };

    loadInitialAssets();
  }, [initialAssets, dispatch, hasLoadedInitialAssets]);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const currentVideoCount = images.filter((img) => img.type === 'video').length;
      const currentImageCount = images.filter((img) => img.type === 'image').length;

      const newVideos = acceptedFiles.filter((file) => file.type.startsWith('video/'));
      const newImages = acceptedFiles.filter((file) => !file.type.startsWith('video/'));

      // Check file sizes

      const oversizedImages = newImages.filter((file) => file.size > MAX_IMAGE_SIZE);
      const oversizedVideos = newVideos.filter((file) => file.size > MAX_VIDEO_SIZE);

      if (oversizedImages.length > 0) {
        toast.error('Images must be smaller than 7.5 MB', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        return;
      }

      if (oversizedVideos.length > 0) {
        toast.error('Videos must be smaller than 50 MB', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        return;
      }

      // Check video limit
      if (currentVideoCount + newVideos.length > 1) {
        toast.error('Only 1 video is allowed', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        return;
      }

      // Check video duration
      if (newVideos.length > 0) {
        const isValidDuration = await checkVideoDuration(newVideos[0]);
        if (!isValidDuration) {
          toast.error('Video duration must be between 5-15 seconds', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
          return;
        }
      }

      // Check image limit
      if (currentImageCount + newImages.length > 24) {
        toast.error('Maximum 24 images are allowed', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        return;
      }

      const filesToAdd = acceptedFiles;
      const maxOrderIndex = Math.max(...images.map((img) => img.orderIndex), -1);

      const processedFiles = await Promise.all(
        filesToAdd.map(async (file, index): Promise<ImageItem> => {
          const isVideo = file.type.startsWith('video/');
          let thumbnailUrl =
            'https://primelister-files.s3.us-west-1.amazonaws.com/image_thumbnail.svg';

          if (isVideo) {
            try {
              thumbnailUrl = await generateVideoThumbnail(file);
            } catch (error) {
              console.error('Error generating video thumbnail:', error);
            }
          }

          return {
            id: `${Date.now()}-${index}`,
            url: URL.createObjectURL(file),
            name: `${isVideo ? 'video' : 'image'}-${Date.now()}-${index}`,
            orderIndex: maxOrderIndex + index + 1,
            file,
            type: isVideo ? 'video' : 'image',
            ...(isVideo && { thumbnailUrl }),
          };
        })
      );

      dispatch(addCreateListingModeImages(processedFiles));
      onImagesChange?.();
    },
    [dispatch, images, onImagesChange]
  );

  const onDropRejected = useCallback((rejectedFiles: any[]) => {
    toast.error('Invalid file type. Only JPEG, PNG and MP4 files are allowed', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
      'video/mp4': ['.mp4'],
    },
    maxFiles: 24,
    noKeyboard: true,
    disabled: isDragging,
  });

  const handleDragStart = useCallback(() => {
    setIsDragging(true);
  }, []);

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      setIsDragging(false);
      setIsDraggingExistingImage(false);
      if (!result.destination) return;

      const items = Array.from(images);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      // Update orderIndex values
      const updatedItems = items.map((item, index) => ({
        ...item,
        orderIndex: index,
      }));

      dispatch(reorderCreateListingModeImages(updatedItems));
      onImagesChange?.();
    },
    [dispatch, images, onImagesChange]
  );

  const handleDeleteImage = (imageId: string, e: React.MouseEvent) => {
    e.stopPropagation();
    
    // Find the image to be deleted
    const imageToDelete = images.find(img => img.id === imageId);
    if (!imageToDelete) return;

    // If trying to delete an image (not video) and it's the last image
    if (imageToDelete.type === 'image') {
      const imageCount = images.filter(img => img.type === 'image').length;
      if (imageCount === 1) {
        toast.error('Please add another image before deleting this one', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        return;
      }
    }
    
    dispatch(removeCreateListingModeImage(imageId));
    onImagesChange?.();
  };

  const handleEditClick = (image: ImageItem, e: React.MouseEvent) => {
    e.stopPropagation();
    setSelectedImageToEdit(image);
    setIsEditDialogOpen(true);
  };

  const handleEditDialogClose = () => {
    setIsEditDialogOpen(false);
    setSelectedImageToEdit(null);
  };

  const handleEditDialogSave = (editedImages: Array<{ id: string; url: string }>) => {
    const updatedImages = images.map((prevImage) => {
      const editedImage = editedImages.find((img) => img.id === prevImage.id);
      if (editedImage) {
        return {
          ...prevImage,
          url: editedImage.url,
        };
      }
      return prevImage;
    });

    dispatch(setCreateListingModeImages(updatedImages));
    handleEditDialogClose();
  };

  const renderUploadBox = (isDragTarget: boolean = false) => (
    <Box
      sx={{
        p: isDragTarget ? 5 : 3,
        outline: 'none',
        borderRadius: 1,
        cursor: 'pointer',
        overflow: 'hidden',
        position: 'relative',
        bgcolor: isDragActive && isDragTarget ? 'action.hover' : 'background.neutral',
        border: (theme) =>
          isDragActive && isDragTarget
            ? `2px dashed ${theme.palette.primary.main}`
            : `1px dashed ${theme.palette.divider}`,
        '&:hover': {
          opacity: 0.72,
          bgcolor: 'action.hover',
        },
        height: isDragTarget ? 200 : 150,
        width: isDragTarget ? '100%' : 150,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1,
        transition: 'all 0.2s ease',
      }}
    >
      <Iconify
        icon={isDragActive && isDragTarget ? 'eva:download-fill' : 'eva:plus-fill'}
        sx={{
          width: isDragTarget ? 40 : 24,
          height: isDragTarget ? 40 : 24,
          color: isDragActive && isDragTarget ? 'primary.main' : 'text.secondary',
          transition: 'all 0.2s ease',
        }}
      />

      {isDragTarget ? (
        <Stack spacing={0.5} sx={{ textAlign: 'center' }}>
          <Typography
            variant="body2"
            sx={{
              color: isDragActive ? 'primary.main' : 'text.primary',
              transition: 'all 0.2s ease',
            }}
          >
            {isDragActive ? 'Drop files here' : 'Drop files here or Browse'}
          </Typography>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            Supported file types: PNG, JPEG, MP4
          </Typography>
        </Stack>
      ) : (
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          Add Image
        </Typography>
      )}
    </Box>
  );

  const handleAddImageClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    const input = document.createElement('input');
    input.type = 'file';
    input.multiple = true;
    input.accept = 'image/jpeg,image/png,video/mp4';
    input.onchange = (e) => {
      const files = (e.target as HTMLInputElement).files;
      if (files) {
        onDrop(Array.from(files));
      }
    };
    input.click();
  };

  return (
    <>
      <Typography variant="h6" sx={{ color: 'text.primary', mt: '32px !important' }}>
        Photos & video
      </Typography>

      <Typography
        variant="body1"
        sx={{ fontSize: '13px', color: 'text.secondary', mb: 3, mt: '0 !important' }}
      >
        Add up to 24 photos & 1 video
      </Typography>

      <Card sx={{ p: 3 }}>
        <Stack spacing={2}>
          {isProcessingInitialAssets || isLoading ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: 200,
                width: '100%',
                bgcolor: 'background.neutral',
                borderRadius: 1,
              }}
            >
              <Stack spacing={2} alignItems="center">
                <CircularProgress />
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Processing images...
                </Typography>
              </Stack>
            </Box>
          ) : (
            <Box
              sx={{
                width: '100%',
                border: (theme) =>
                  images.length > 0
                    ? isDragActive
                      ? `2px dashed ${theme.palette.primary.main}`
                      : `2px solid ${theme.palette.divider}`
                    : 'none',
                borderRadius: 1,
                transition: 'all 0.2s ease',
                bgcolor: images.length > 0 && isDragActive ? 'action.hover' : 'transparent',
                p: 2,
                '&:hover': {
                  bgcolor: images.length > 0 ? 'background.neutral' : 'transparent',
                },
              }}
            >
              {images.length === 0 ? (
                <Box {...getRootProps()}>
                  <input {...getInputProps()} />
                  {renderUploadBox(true)}
                </Box>
              ) : (
                <>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mb: 2 }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ color: isDragActive ? 'primary.main' : 'text.secondary' }}
                    >
                      {isDragActive ? 'Drop files here' : 'Drag to reorder images'}
                    </Typography>
                  </Stack>

                  <Box
                    {...getRootProps()}
                    sx={{
                      width: '100%',
                      height: '100%',
                      position: 'relative',
                      pointerEvents: isDragging ? 'none' : 'auto',
                    }}
                  >
                    <input {...getInputProps()} />
                    <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
                      <Droppable droppableId="images" direction="horizontal">
                        {(provided: DroppableProvided) => (
                          <Stack
                            direction="row"
                            flexWrap="wrap"
                            gap={2}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            sx={{
                              minHeight: 170,
                              display: 'inline-flex',
                              flex: 1,
                              position: 'relative',
                              transition: 'all 0.3s ease-in-out',
                              height: 'auto',
                              overflow: 'hidden',
                            }}
                          >
                            {visibleImages.map((image, index) => {
                              const imageId = image.id || `generated-${index}`;

                              return image.type === 'video' ? (
                                <Box
                                  key={imageId}
                                  sx={{
                                    position: 'relative',
                                    width: 150,
                                    height: 150,
                                    borderRadius: 1,
                                    overflow: 'hidden',
                                    transition: 'all 0.3s ease-in-out',
                                    zIndex: 1,
                                    marginLeft: isDragging ? '150px' : 0,
                                    opacity: 1,
                                  }}
                                >
                                  <Box
                                    component="img"
                                    src={image.thumbnailUrl}
                                    alt={`Video ${index + 1}`}
                                    onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                                      const target = e.target as HTMLImageElement;
                                      target.style.backgroundColor = '#F4F6F8';
                                      target.src =
                                        'https://primelister-files.s3.us-west-1.amazonaws.com/image_thumbnail.svg';
                                    }}
                                    sx={{
                                      width: '100%',
                                      height: '100%',
                                      objectFit: 'cover',
                                    }}
                                  />
                                  <Box
                                    sx={{
                                      position: 'absolute',
                                      top: 8,
                                      left: 8,
                                      bgcolor: 'rgba(0, 0, 0, 0.6)',
                                      borderRadius: '4px',
                                      padding: '2px 6px',
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: 0.5,
                                    }}
                                  >
                                    <Iconify
                                      icon="eva:video-fill"
                                      sx={{ color: 'white', width: 16, height: 16 }}
                                    />
                                    <Typography variant="caption" sx={{ color: 'white' }}>
                                      Video
                                    </Typography>
                                  </Box>
                                  <Box
                                    className="image-overlay"
                                    sx={{
                                      position: 'absolute',
                                      top: 0,
                                      left: 0,
                                      right: 0,
                                      bottom: 0,
                                      bgcolor: 'rgba(22, 28, 36, 0.64)',
                                      opacity: 0,
                                      transition: 'opacity 0.2s ease',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      '&:hover': {
                                        opacity: 1,
                                      },
                                    }}
                                  >
                                    <IconButton
                                      onClick={(e) => handleDeleteImage(image.id, e)}
                                      sx={{
                                        position: 'absolute',
                                        top: 4,
                                        right: 4,
                                        color: 'white',
                                        '&:hover': {
                                          bgcolor: 'rgba(255, 255, 255, 0.1)',
                                        },
                                      }}
                                    >
                                      <Iconify icon="eva:close-fill" width={20} height={20} />
                                    </IconButton>
                                  </Box>
                                </Box>
                              ) : (
                                <Draggable key={imageId} draggableId={imageId} index={index}>
                                  {(
                                    dragProvided: DraggableProvided,
                                    snapshot: DraggableStateSnapshot
                                  ) => (
                                    <Box
                                      ref={dragProvided.innerRef}
                                      {...dragProvided.draggableProps}
                                      {...dragProvided.dragHandleProps}
                                      sx={{
                                        position: 'relative',
                                        width: 150,
                                        height: 150,
                                        borderRadius: 1,
                                        overflow: 'hidden',
                                        cursor: 'move',
                                        opacity: snapshot.isDragging ? 0.6 : 1,
                                        transition: 'all 0.3s ease-in-out',
                                        '&:hover': {
                                          '& .image-overlay': {
                                            opacity: 1,
                                          },
                                        },
                                        boxShadow: snapshot.isDragging
                                          ? (theme) => theme.shadows[10]
                                          : 'none',
                                        zIndex: 1,
                                      }}
                                    >
                                      <Box
                                        component="img"
                                        src={convertToAbsoluteUrl(image.url)}
                                        alt={`Image ${index + 1}`}
                                        onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                                          const target = e.target as HTMLImageElement;
                                          target.style.backgroundColor = '#F4F6F8';
                                          target.src =
                                            'https://primelister-files.s3.us-west-1.amazonaws.com/image_thumbnail.svg';
                                        }}
                                        sx={{
                                          width: '100%',
                                          height: '100%',
                                          objectFit: 'cover',
                                        }}
                                      />
                                      <Box
                                        className="image-overlay"
                                        onClick={(e) => handleEditClick(image, e)}
                                        sx={{
                                          position: 'absolute',
                                          top: 0,
                                          left: 0,
                                          right: 0,
                                          bottom: 0,
                                          bgcolor: 'rgba(22, 28, 36, 0.64)',
                                          opacity: 0,
                                          transition: 'opacity 0.2s ease',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                        }}
                                      >
                                        <Typography
                                          variant="subtitle1"
                                          sx={{
                                            color: 'white',
                                            fontWeight: 'medium',
                                          }}
                                        >
                                          Edit
                                        </Typography>
                                        {/* Hide close button if type is EDIT and there's only one image */}
                                        {!(
                                          type === ImageUploadGridTypeEnum.EDIT &&
                                          images.length === 1
                                        ) && (
                                          <IconButton
                                            onClick={(e) => handleDeleteImage(image.id, e)}
                                            sx={{
                                              position: 'absolute',
                                              top: 4,
                                              right: 4,
                                              color: 'white',
                                              '&:hover': {
                                                bgcolor: 'rgba(255, 255, 255, 0.1)',
                                              },
                                            }}
                                          >
                                            <Iconify icon="eva:close-fill" width={20} height={20} />
                                          </IconButton>
                                        )}
                                      </Box>
                                    </Box>
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                            <Box
                              onClick={handleAddImageClick}
                              sx={{
                                zIndex: 1,
                              }}
                            >
                              {renderUploadBox()}
                            </Box>
                          </Stack>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Box>
                  {hasMoreImages && (
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        mt: 2,
                        pointerEvents: 'auto',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowAll(!showAll);
                      }}
                    >
                      <Stack
                        direction="row"
                        spacing={0.5}
                        alignItems="center"
                        sx={{
                          transition: 'all 0.2s ease-in-out',
                          '&:hover': {
                            transform: 'translateY(-1px)',
                          },
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{
                            color: 'primary.main',
                            cursor: 'pointer',
                            '&:hover': {
                              textDecoration: 'underline',
                            },
                          }}
                        >
                          {showAll ? 'See Less' : `See More (${images.length - 12} more)`}
                        </Typography>
                        <Iconify
                          icon={showAll ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'}
                          sx={{
                            width: 20,
                            height: 20,
                            color: 'primary.main',
                            transition: 'transform 0.2s ease-in-out',
                            transform: showAll ? 'rotate(0deg)' : 'rotate(0deg)',
                          }}
                        />
                      </Stack>
                    </Box>
                  )}
                </>
              )}
            </Box>
          )}
        </Stack>

        {selectedImageToEdit && (
          <ImageEditDialog
            open={isEditDialogOpen}
            onClose={handleEditDialogClose}
            images={images}
            onSave={handleEditDialogSave}
            initialImageId={selectedImageToEdit.id}
          />
        )}
      </Card>
    </>
  );
}
