import { GridSelectionModel } from '@mui/x-data-grid-pro';
import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from 'src/store';
import { setBulkActionsListingList } from 'src/store/dashboard/slices/inventorySlice';
import { IBulkActionsSelectedListings } from '../pages/dashboard/Inventory/types/dataGridInterfaces';
import { IPlatforms } from 'src/store/dashboard/slices/myShopsSlice';
import { Marketplace } from '@beta.limited/primelister';

export const useListingDataWithSelection = () => {
  const { bulkActionsListingList, inventory, inventoryCount } = useSelector(
    (state: IRootState) => state.inventory
  );

  const dispatch = useDispatch();
  const filteredData = useCallback(() => {
    const uniqueInventory = inventory.filter(
      (item, index, self) => self.findIndex((i) => i._id === item._id) === index
    );
    return uniqueInventory;
  }, [inventory, inventoryCount]);

  const getNewlyAddedListings = (
    checkboxes: GridSelectionModel
  ): IBulkActionsSelectedListings[] => {
    const newlyAddedListings: IBulkActionsSelectedListings[] = [];
    const bulkActionListingsIDs: string[] = bulkActionsListingList.map((item) => item._id);
    const newCheckboxes = checkboxes.filter(
      (checkbox) => !bulkActionListingsIDs.includes(checkbox as string)
    );

    newCheckboxes.forEach((checkbox) => {
      const newListing = filteredData().find((item) => item._id === checkbox);
      if (newListing) {
        const bulkActionData: IBulkActionsSelectedListings = {
          _id: newListing._id,
          title: newListing.title,
          marketplaceSources: newListing.refs,
          image: newListing.thumbnail,
          sku: newListing.sku,
          groups: newListing.groups,
          tags: newListing.listingTags,
          status: newListing.status,
        };
        newlyAddedListings.push(bulkActionData);
      }
    });

    return newlyAddedListings;
  };

  const removeDeselectedListings = (
    checkboxes: GridSelectionModel
  ): IBulkActionsSelectedListings[] =>
    bulkActionsListingList.filter((item) => checkboxes.includes(item._id));

  const getVisibleBulkActionsListings = (selectedCheckboxes: string[]) => {
    const visibleListings = filteredData().filter((listing) =>
      selectedCheckboxes.includes(listing._id)
    );

    const visibleListingsWithAdjustedData = visibleListings.map((listing) => {
      const bulkListingData: IBulkActionsSelectedListings = {
        _id: listing._id,
        title: listing.title,
        marketplaceSources: listing.refs,
        image: listing.thumbnail,
        sku: listing.sku,
        tags: listing.listingTags,
        groups: listing.groups,
        status: listing.status,
      };
      return bulkListingData;
    });

    dispatch(setBulkActionsListingList(visibleListingsWithAdjustedData));
  };

  return {
    getNewlyAddedListings,
    removeDeselectedListings,
    getVisibleBulkActionsListings,
  };
};
