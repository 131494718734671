// @mui
import { Stack, Avatar, Typography, TextField, Button, InputAdornment, Alert } from '@mui/material';
// hooks
import useResponsive from 'src/hooks/useResponsive';
import Iconify from 'src/components/iconify';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from 'src/store';
import * as Yup from 'yup';

import {
  inventoryEditSelectedItemRequest,
  setIsInventoryLinkDeleteDialogOpen,
  setIsLastLinkRemoveErrorDialogOpen,
  setLinkDeleteDialogData,
  setCreateModeLink,
  removeCreateModeLink,
} from 'src/store/dashboard/slices/inventorySlice';
import { useParams } from 'react-router';
import { PlatformLinksMode } from './PlatformLinksContainer';

interface IPlatformLinksForm {
  key: string;
  platform: {
    name: string;
    url: string;
    displayName: string;
    linkValidation: string;
  };
  mode?: PlatformLinksMode;
}

export default function PlatfromLinksForm({
  key,
  platform,
  mode = PlatformLinksMode.EDIT,
}: IPlatformLinksForm) {
  const isDesktop = useResponsive('up', 'md');
  const dispatch = useDispatch();
  const { id } = useParams();
  const { selectedListingDetails, createModeLinks } = useSelector(
    (state: IRootState) => state.inventory
  );

  // Button handlers
  const handleRemoveLink = () => {
    dispatch(setLinkDeleteDialogData({ name: platform.name, displayName: platform.displayName }));
    if (Object.keys(selectedListingDetails.refs!).length > 1) {
      dispatch(setIsInventoryLinkDeleteDialogOpen(true));
    } else {
      dispatch(setIsLastLinkRemoveErrorDialogOpen(true));
    }
  };

  const handleOpenLink = () => {
    window.open(initialValues.listingLink, '_blank', 'noopener noreferrer');
  };

  const getIdAndStoreNameFromLink = (listingLink: string) => {
    let closetName = '';
    let listingId = '';

    if (platform.name === 'shopify') {
      const regex = /^https:\/\/admin\.shopify\.com\/store\/([^\/]+)\/products\/([^\/]+)/;
      const match = listingLink.match(regex);
      closetName = match ? match[1] : '';
      listingId = match ? match[2] : '';
      if (match && match[3]) {
        listingId += match[3].replace(/\//g, ''); // remove all slashes
      }
    } else if (listingLink.includes('grailed')) {
      const regex = new RegExp(platform.linkValidation + '([^/\\W]+)');
      const match = listingLink.match(regex);
      if (match) {
        listingId = match[1];
      }
    } else if (listingLink.includes('etsy')) {
      /* 
        https?:\\/\\/(?:www\\.)?etsy\\.com\\/: Matches the protocol and domain part of the URL.
        (?:\\w{2}\\/)?: Optionally matches a two-letter country code followed by a slash, accommodating URLs that specify a country code.
        (?:listing\\/)?: Optionally matches the listing/ segment, allowing for URLs that include this part and those that don't.
        (\\d+): Captures a sequence of digits, which is assumed to be the listing ID.
      */
      const customLinkValidation = 'https?:\\/\\/(?:www\\.)?etsy\\.com\\/';
      const regex = new RegExp(customLinkValidation + '(?:\\w{2}\\/)?(?:listing\\/)?(\\d+)');
      const match = listingLink.match(regex);
      if (match) {
        listingId = match[1];
      }
    } else if (listingLink.includes('depop')) {
      const regex = new RegExp(platform.linkValidation + '(.+)');
      const match = listingLink.match(regex);
      if (match) {
        listingId = match[1];
      }
    } else if (listingLink.includes('mercari')) {
      const regex = new RegExp(platform.linkValidation + '([^\\W_]+)');
      const match = listingLink.match(regex);
      if (match) {
        listingId = match[1];
        const specialChars = /[^\w\s]/gi;
        const matchSpecialChar = listingId.match(specialChars);
        if (matchSpecialChar) {
          listingId = listingId.slice(0, matchSpecialChar.index);
        }
      }
    } else {
      const regex = new RegExp(platform.linkValidation + '(?:.*-)?(.+?)(/+)?$');
      const match = listingLink.match(regex);
      if (match) {
        listingId = match[1];
        if (match[2]) {
          listingId = listingId.replace(/\/+$/, ''); // remove all trailing slashes
        }
      }
    }
    return { closetName, listingId };
  };

  const handleSave = () => {
    const { listingId, closetName } = getIdAndStoreNameFromLink(handlePlatfromLinkItemFormik.values.listingLink);

    const updatedLinkObject = {
      ...(selectedListingDetails?.refs?.[platform.name] || {}),
      added: selectedListingDetails?.refs?.[platform.name]?.added || new Date().getTime(),
      updated: new Date().getTime(),
      id: listingId,
    };
    if (platform.name === 'shopify') updatedLinkObject.closetName = closetName;

    const updatedRefsData = { ...selectedListingDetails?.refs, [platform.name]: updatedLinkObject };
    const updatedRefs = { refs: updatedRefsData };

    const isListingLinkEmpty =
      handlePlatfromLinkItemFormik.values.listingLink === '' ||
      handlePlatfromLinkItemFormik.values.listingLink === null;
    const hasOnlyOneSelectedListingDetail = Object.keys(selectedListingDetails.refs!).length === 1;

    dispatch(setLinkDeleteDialogData({ name: platform.name, displayName: platform.displayName }));
    if (isListingLinkEmpty && hasOnlyOneSelectedListingDetail) {
      dispatch(setIsLastLinkRemoveErrorDialogOpen(true));
      handlePlatfromLinkItemFormik.setFieldValue('listingLink', initialValues.listingLink);
    } else if (isListingLinkEmpty) {
      dispatch(setIsInventoryLinkDeleteDialogOpen(true));
    } else {
      dispatch(
        inventoryEditSelectedItemRequest({ id, editData: updatedRefs, editType: 'saveLink' })
      );
    }
  };

  // Formik essentials

  const initialValues = {
    listingLink:
      mode === PlatformLinksMode.CREATE
        ? createModeLinks[platform.name]?.listingLink || ''
        : selectedListingDetails?.refs?.[platform.name]?.listingLink || '',
  };

  const formValidation = Yup.object({
    listingLink: Yup.string()
      .test(
        'includes-platform-link-validation',
        `Oops! Invalid link. 😕 Please enter a valid link in the correct format to save your ${platform.displayName} listing.`,
        (value) => {
          if (!value || platform.name === 'shopify') return true; // allow empty values
          else if (platform.name === 'etsy') {
            const regex = new RegExp(
              `^https?:\\/\\/(?:www\\.)?${platform.linkValidation}(?:\\w{2}\\/)?listing\\/(\\d+)(?:\\/[^\\/]+)?/?$`,
              'i'
            );
            return regex.test(value);
          } else {
            const regex = new RegExp(`^https?:\\/\\/(?:www\\.)?${platform.linkValidation}`, 'i');
            return regex.test(value);
          }
        }
      )
      .test(
        'includes-https-link',
        'Oops! Invalid link. 😕 Please enter a valid link in the correct format to save your listing.',
        (value) => {
          if (!value) return true; // allow empty values
          return value.includes('https://');
        }
      )
      .test(
        'shopify-closet-name-validation',
        'Oops! Invalid link. 😕 Please enter a valid link in the correct format to save your Shopify listing.',
        (value) => {
          if (!value) return true; // allow empty values
          if (platform.name === 'shopify') {
            const regex = /https?:\/\/admin\.shopify\.com\/store\/[^/]+\/products\/(\d+)(\/+)?/;
            const match = value?.match(regex);
            return !!(match && match[1].length >= 2); // convert to boolean
          }
          return true;
        }
      ),
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handlePlatfromLinkItemFormik.handleChange(e);

    if (mode === PlatformLinksMode.CREATE) {
      const { value } = e.target;

      // If empty value, remove from state
      if (!value) {
        dispatch(removeCreateModeLink(platform.name));
        return;
      }

      // Check if the value passes validation
      const isValid = formValidation.fields.listingLink.isValidSync(value);
      const { listingId, closetName } = getIdAndStoreNameFromLink(value);

      const updatedLinkObject = {
        listingLink: value,
        id: listingId,
        added: createModeLinks[platform.name]?.added || new Date().getTime(),
        updated: new Date().getTime(),
        isValid,
        ...(closetName && { closetName }),
      };

      dispatch(setCreateModeLink({ platform: platform.name, link: updatedLinkObject }));
    }
  };

  const handlePlatfromLinkItemFormik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    initialValues: initialValues,
    validationSchema: formValidation,
    onSubmit: handleSave,
  });

  const shouldDisplayButton =
    !initialValues.listingLink ||
    initialValues.listingLink !== handlePlatfromLinkItemFormik.values.listingLink;

  const hasListingLinkChanged =
    handlePlatfromLinkItemFormik.errors.listingLink ||
    initialValues.listingLink === handlePlatfromLinkItemFormik.values.listingLink
      ? true
      : false;

  return (
    <form onSubmit={handlePlatfromLinkItemFormik.handleSubmit}>
      <Stack
        key={key}
        spacing={isDesktop ? 1.5 : 2}
        direction={'row'}
        alignItems={isDesktop ? 'center' : 'flex-start'}
        sx={{
          p: 1.5,
          borderRadius: 2,
          border: (theme) => `solid 1px ${theme.palette.divider}`,
          '&:hover': {
            bgcolor: 'background.paper',
            boxShadow: (theme) => theme.customShadows.z20,
          },
          ...(isDesktop && {
            borderRadius: 1.5,
          }),
        }}
      >
        <Stack alignSelf="center">
          <Avatar src={platform.url} sx={{ width: 40, height: 40 }} />
        </Stack>

        <Stack
          sx={{
            width: 1,
            flexGrow: { sm: 1 },
          }}
          spacing={1.5}
        >
          <Typography variant="subtitle1" noWrap>
            {platform.displayName}
          </Typography>

          <Stack
            direction={isDesktop ? 'row' : 'column'}
            spacing={2}
            sx={{
              flexGrow: { sm: 1 },
              alignItems: isDesktop ? 'center' : 'flex-start',
            }}
          >
            <Stack
              sx={{
                width: !isDesktop ? 1 : null,
                flexGrow: { sm: 1 },
              }}
              spacing={1}
            >
              <TextField
                name="listingLink"
                fullWidth
                placeholder={`Enter your ${platform.displayName} listing link here`}
                value={handlePlatfromLinkItemFormik.values.listingLink}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify icon="material-symbols:link" width={24} />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  flexGrow: { sm: 1 },
                  '& .MuiOutlinedInput-root': {
                    maxHeight: '2.5rem',
                    bgcolor: `${
                      handlePlatfromLinkItemFormik.errors.listingLink ? '#FFF2EF' : '#eeeff1'
                    }`,
                    color: `${
                      handlePlatfromLinkItemFormik.errors.listingLink ? '#B71C19' : '#000000'
                    }`,
                  },
                }}
              />
            </Stack>
            <Stack
              direction="row"
              sx={{
                alignItems: 'center',
                minWidth: '20%',
              }}
              spacing={1}
            >
              {mode === PlatformLinksMode.EDIT && !shouldDisplayButton && (
                <Button
                  variant="soft"
                  sx={{ maxHeight: '2rem', minWidth: 'max-content' }}
                  color="inherit"
                  onClick={handleOpenLink}
                >
                  Open Link
                </Button>
              )}
              {mode === PlatformLinksMode.EDIT && !shouldDisplayButton && (
                <Button
                  variant="soft"
                  sx={{ maxHeight: '2rem', minWidth: 'max-content' }}
                  color="error"
                  onClick={handleRemoveLink}
                >
                  Remove Link
                </Button>
              )}
              {mode === PlatformLinksMode.EDIT && shouldDisplayButton && (
                <Button
                  variant="soft"
                  sx={{ maxHeight: '2rem', minWidth: 'max-content' }}
                  color="primary"
                  type="submit"
                  disabled={hasListingLinkChanged}
                >
                  Save Link
                </Button>
              )}
            </Stack>
          </Stack>
          {handlePlatfromLinkItemFormik.errors.listingLink && (
            <Alert
              sx={{
                width: '100%',
                height: '1.5rem',
                display: 'flex',
                alignItems: 'center',
                alignSelf: 'flex-start',
                bgcolor: 'white',
                color: '#FF5630',
                pl: 0.4,
              }}
              severity="error"
              variant="standard"
              icon={false}
            >
              {handlePlatfromLinkItemFormik.errors.listingLink as string}
            </Alert>
          )}
        </Stack>
      </Stack>
    </form>
  );
}
