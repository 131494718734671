import {
  ProductCardCheckedCheckboxIcon,
  ProductCardCheckedCheckboxIconMobile,
  ProductCardDefaultCheckboxIcon,
  ProductCardDefaultCheckboxIconMobile,
  ProductCardIndeterminateCheckboxIcon,
  ProductCardIndeterminateCheckboxIconMobile,
} from './styles';
import { Checkbox } from '@mui/material';
import { ProductCardProps, ProductCardSelectionEnum } from './types';

const ProductCheckbox = ({
  selection,
  isMobile = false,
}: {
  selection: ProductCardProps['selection'];
  isMobile?: boolean;
}) => (
  <Checkbox
    checkedIcon={
      !isMobile ? <ProductCardCheckedCheckboxIcon /> : <ProductCardCheckedCheckboxIconMobile />
    }
    indeterminateIcon={
      !isMobile ? (
        <ProductCardIndeterminateCheckboxIcon />
      ) : (
        <ProductCardIndeterminateCheckboxIconMobile />
      )
    }
    icon={!isMobile ? <ProductCardDefaultCheckboxIcon /> : <ProductCardDefaultCheckboxIconMobile />}
    checked={selection === ProductCardSelectionEnum.ALL}
    indeterminate={selection === ProductCardSelectionEnum.INDETERMINATE}
    sx={{
      ...(isMobile && {
        '& svg': {
          width: '20px !important',
          height: '20px !important',
        },
        marginLeft: 'auto'
      }),
      '& .MuiTouchRipple-child': {
        backgroundColor: '#919EAB14',
      },
      '&:hover': {
        backgroundColor: '#919EAB14',
      },
      '& .MuiButtonBase-root': {
        padding: '0px !important',
      },
    }}
  />
);

export default ProductCheckbox;
