import { LoadingButton } from '@mui/lab';
import {
  Card,
  Grid,
  Stack,
  Box,
  TextField,
  Autocomplete,
  MenuItem,
  Chip,
  Typography,
  FilterOptionsState,
  useTheme,
  Skeleton,
} from '@mui/material';
import { FormikProps, useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from 'src/store';
import { useNavigate } from 'react-router-dom';
import { Marketplace } from '@beta.limited/primelister';

import {
  setPopoverData,
  setDeletePopup,
  QuickActionsTagPopoverModeEnum,
  setQuickEditSelectedTags,
  inventoryUpdateTagsRequest,
  inventoryRemoveGroupsRequest,
  inventoryAddToGroupRequest,
  setSelectedGroupToAdd,
  setIsEditFormSaveChangesDisabled,
  setIsTagsAutocompleteOpen,
  setIsGroupAutocompleteOpen,
  setIsTagEditPopoverOpen,
  setQuickActionsTagPopoverMode,
} from 'src/store/dashboard/slices/inventorySlice';
import { useEffect, useMemo, useState } from 'react';
import { IGroupData, ITagData } from 'src/store/dashboard/slices/groupsAndTagsSlice';
import { useTagUtilities } from '../../../helpers/useTagUtilities';
import { LabelColor } from 'src/components/label';
import _, { isEqual } from 'lodash';
import TagEditPopover from '../../InventoryPage/others/quick-edit/TagEditPopover';
import TagsDeleteDialog from 'src/pages/dashboard/GroupsAndTags/Tags/TagsTable/TagsDeleteDialog';
import { useGroupAutocomplete } from 'src/hooks/useGroupAutocomplete';
import { toast } from 'react-toastify';
import GroupEditPopover from '../../InventoryPage/others/quick-edit/GroupEditPopover';
import GroupsDeleteDialog from 'src/pages/dashboard/GroupsAndTags/Groups/groupsTable/GroupsDeleteDialog';
import NoOptionsFound from '../../InventoryPage/others/quick-edit/NoOptionsFound';
import GroupMenuItem from '../../InventoryPage/others/quick-edit/GroupMenuItem';
import { useTagAutocompleteClickOutside } from 'src/hooks/useTagAutocompleteClickOutside';
import CreateListingDetailsForm from './CreateListingDetailsForm';
import ImageUploadGrid, { ImageUploadGridTypeEnum } from './ImageUploadGrid';

const CREATE_MESSAGE = 'Item successfully created';

interface CreateListingFormProps {
  marketplace: Marketplace;
  onFormChange: (isDirty: boolean, data: IInitialValues) => void;
}

export interface IInitialValues {
  [key: string]: string | number | ITagData[] | IGroupData | null;
  sku: string;
  status: string;
  groups: IGroupData | null;
  notes: string;
  listingTags: ITagData[];
  title: string;
  description: string;
  price: string;
  quantity: string;
  condition: string;
  brand: string;
  color: string;
  size: string;
  material: string;
  style: string;
  pattern: string;
  category: string;
  subcategory: string;
}

export default function CreateListingForm({ marketplace, onFormChange }: CreateListingFormProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { groupsData } = useSelector((state: IRootState) => state.groupsAndTags);
  const {
    quickActionsTagPopoverMode,
    quickEditSelectedTags,
    isEditFormSaveChangesDisabled,
    isGroupAutocompleteOpen,
    isTagsAutocompleteOpen,
    isTagEditPopoverOpen,
    createListingLoading,
  } = useSelector((state: IRootState) => state.inventory);

  const { getTagColor, handleTagsUpdated, renderTagMenuItem, tagsOptions, handleCreateNewTag } =
    useTagUtilities();

  const initialValues: IInitialValues = {
    sku: '',
    status: '',
    groups: null,
    notes: '',
    listingTags: [],
    title: '',
    description: '',
    price: '',
    quantity: '',
    condition: '',
    brand: '',
    color: '',
    size: '',
    material: '',
    style: '',
    pattern: '',
    category: '',
    subcategory: '',
  };

  const handleCreateFormik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validate: (values) => {
      const errors: Partial<IInitialValues> = {};
      if (!values.title.trim()) {
        errors.title = 'Title is required';
      }
      return errors;
    },
    onSubmit: (values) => {
      if (!values.title.trim()) {
        return;
      }

      toast.success(CREATE_MESSAGE, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      navigate('/inventory');
    },
  });

  useEffect(() => {
    dispatch(setIsEditFormSaveChangesDisabled(!handleCreateFormik.dirty));
  }, [handleCreateFormik.dirty, dispatch]);

  useEffect(() => {
    onFormChange(handleCreateFormik.dirty, handleCreateFormik.values);
  }, [handleCreateFormik.dirty, handleCreateFormik.values, onFormChange]);

  const handleFieldChange =
    (field: keyof IInitialValues) =>
    (
      newValue:
        | string
        | ITagData[]
        | IGroupData
        | React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>
    ) => {
      let value: string | ITagData[] | IGroupData;

      if (newValue && typeof newValue === 'object' && 'target' in newValue) {
        value = newValue.target.value as string;
      } else {
        value = newValue as string | ITagData[] | IGroupData;
      }

      handleCreateFormik.setFieldValue(field as string, value);
    };

  const theme = useTheme();
  const {
    isGroupSelected,
    isGroupInEditMode,
    isGroupNoOptionsOpen,
    setIsGroupNoOptionsOpen,
    groupInputValue,
    setGroupInputValue,
    handleEditIconClick,
    handleCreateNewGroup,
    filterOptions,
    isGroupInCreateMode,
    autocompleteRef,
    handleAutocompleteClose,
    handleAutocompleteOpen,
    handlePopoverClose,
    handlePopoverOpen,
    ignoreBlurRef,
    setSelectedGroupValue,
  } = useGroupAutocomplete(handleCreateFormik.values.groups);

  const [isTagNoOptionsOpen, setIsTagNoOptionsOpen] = useState(false);
  const { autocompleteRef: tagsAutocompleteRef, tagEditPopoverRef } =
    useTagAutocompleteClickOutside(
      isTagsAutocompleteOpen,
      isTagEditPopoverOpen,
      isTagNoOptionsOpen
    );
  const [tagInputValue, setTagInputValue] = useState<string>('');
  const isTagInEditMode = useMemo(
    () => quickActionsTagPopoverMode === QuickActionsTagPopoverModeEnum.EDIT,
    [quickActionsTagPopoverMode]
  );
  const isTagInCreateMode = useMemo(
    () => quickActionsTagPopoverMode === QuickActionsTagPopoverModeEnum.CREATE,
    [quickActionsTagPopoverMode]
  );

  const renderStatus = () =>
    createListingLoading ? (
      <Skeleton variant="rounded" sx={{ width: '100%', height: '56px' }} />
    ) : (
      <TextField
        select
        name="status"
        label="Status"
        placeholder="Status"
        value={handleCreateFormik.values.status}
        onChange={handleFieldChange('status')}
        onBlur={handleCreateFormik.handleBlur}
      >
        <MenuItem value={'Listed'}>Listed</MenuItem>
        <MenuItem value={'Sold'}>Sold</MenuItem>
        <MenuItem value={'Draft'}>Draft</MenuItem>
      </TextField>
    );

  const handleGroupSelect = (selectedGroup: IGroupData | null) => {
    handleCreateFormik.setFieldValue('groups', selectedGroup);
  };

  const renderGroups = () =>
    createListingLoading ? (
      <Skeleton variant="rounded" sx={{ width: '100%', height: '56px' }} />
    ) : (
      <Autocomplete
        ref={autocompleteRef}
        fullWidth
        freeSolo
        clearOnBlur
        blurOnSelect={false}
        handleHomeEndKeys
        includeInputInList={true}
        open={isGroupAutocompleteOpen}
        onOpen={handleAutocompleteOpen}
        onClose={() => {
          handleAutocompleteClose();
          setGroupInputValue('');
          setIsGroupNoOptionsOpen(false);
        }}
        selectOnFocus
        onBlurCapture={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onInputChange={(event, newInputValue) => {
          setGroupInputValue(newInputValue);
        }}
        onChange={(e, newValue) => {
          handleCreateFormik.setFieldValue('groups', newValue);
        }}
        ListboxProps={{
          sx: {
            overflow: isGroupInEditMode || isGroupInCreateMode ? 'hidden' : 'auto',
            '& .MuiAutocomplete-option[aria-selected="true"]': {
              backgroundColor: 'rgba(32, 101, 209, 0.16) !important',
              '&.Mui-focused': {
                backgroundColor: 'rgba(32, 101, 209, 0.16) !important',
              },
            },
            '& .MuiAutocomplete-option.Mui-focused': {
              backgroundColor: 'rgba(32, 101, 209, 0.08)',
            },
          },
        }}
        options={groupsData}
        filterOptions={filterOptions}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          return option.name || '';
        }}
        renderOption={(props, option) => {
          if (isGroupNoOptionsOpen) {
            return renderNoOptions('group');
          }
          return (
            <li
              style={{
                ...(isGroupSelected(option) && {
                  backgroundColor: 'rgba(32, 101, 209, 0.16)',
                }),
                ...(isGroupInEditMode || isGroupInCreateMode
                  ? {
                      opacity: 0.48,
                    }
                  : {}),
              }}
              key={option.id}
              {...props}
            >
              {renderGroupMenuItem(option)}
            </li>
          );
        }}
        value={handleCreateFormik.values.groups}
        onBlur={handleCreateFormik.handleBlur}
        renderInput={(params) => (
          <TextField
            {...params}
            key={handleCreateFormik.values.groups?.id}
            label="Inventory Group"
            InputProps={{
              ...params.InputProps,
              sx: {
                ...(isGroupInEditMode || isGroupInCreateMode
                  ? {
                      color: '#919EAB',
                    }
                  : {}),
              },
            }}
            placeholder="Select a group"
            size="medium"
            sx={{
              fontSize: '16px !important',
              width: '100%',
              '& .MuiInputLabel-root.Mui-focused': {
                borderColor: '#E5E8EB',
                fontSize: '16px !important',
              },
              '& .MuiInputLabel-root:not(.Mui-focused)': {
                fontSize: '16px !important',
                fontWeight: 400,
                color: '#919EAB !important',
              },
            }}
          />
        )}
        slotProps={{
          paper: {
            sx: {
              '& .MuiAutocomplete-noOptions': {
                height: '94px',
                overflow: 'hidden',
                padding: '4px 4px 8px 4px',
              },
            },
          },
        }}
        sx={{
          '& .MuiInputLabel-root': {
            fontWeight: 600,
            color: '#212B36',
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#212B36',
          },
          '& .MuiInputLabel-root:not(.Mui-focused)': {
            color: '#637381 !important',
          },
          '& .MuiInputBase-input': {
            fontSize: '14px !important',
            fontWeight: 400,
            lineHeight: '22px',
          },
          '& .MuiInputBase-root': {
            minHeight: '56px',
          },
        }}
      />
    );

  const renderNoOptions = (type: 'group' | 'tag') => (
    <NoOptionsFound
      type={type}
      onMouseDown={(e) => {
        if (type === 'group') {
          e.preventDefault();
          ignoreBlurRef.current = true;
        }
      }}
      inputValue={type === 'group' ? groupInputValue : tagInputValue}
      onCreateClick={(e) => {
        if (type === 'group') {
          handleCreateNewGroup(e);
          dispatch(setIsEditFormSaveChangesDisabled(true));
        } else {
          handleCreateNewTag(e, tagInputValue);
        }
      }}
    />
  );

  const renderGroupMenuItem = (option: IGroupData) => (
    <GroupMenuItem
      option={option}
      onSelect={handleGroupSelect}
      onEditClick={handleEditIconClick}
      onClose={handleAutocompleteClose}
    />
  );

  const isTagSelected = (option: ITagData) =>
    handleCreateFormik.values.listingTags.some((tag) => tag._id === option._id);

  const handleTagUnselect = (tagId: string) => {
    const updatedTags = handleCreateFormik.values.listingTags.filter((tag) => tag._id !== tagId);
    handleCreateFormik.setFieldValue('listingTags', updatedTags);
  };

  const renderTags = () =>
    createListingLoading ? (
      <Skeleton variant="rounded" sx={{ width: '100%', height: '56px' }} />
    ) : (
      <Autocomplete
        fullWidth
        ref={tagsAutocompleteRef}
        multiple
        freeSolo
        open={isTagsAutocompleteOpen}
        onOpen={() => dispatch(setIsTagsAutocompleteOpen(true))}
        onClose={(event, reason) => {
          setTagInputValue('');
          setIsTagNoOptionsOpen(false);
          dispatch(setIsTagsAutocompleteOpen(false));
        }}
        limitTags={3}
        value={handleCreateFormik.values.listingTags}
        onInputChange={(event, newInputValue) => {
          setTagInputValue(newInputValue);
        }}
        ListboxProps={{
          sx: {
            overflow: isTagInEditMode || isTagInCreateMode ? 'hidden' : 'auto',
          },
        }}
        onChange={(event, newValue: (string | ITagData)[]) => {
          const uniqueTags = newValue
            .map((value) => {
              if (typeof value === 'string') {
                return { name: value, _id: '', color: '' };
              }
              return value;
            })
            .filter(
              (tag, index, self) =>
                index === self.findIndex((t) => t._id === tag._id || t.name === tag.name)
            );
          handleCreateFormik.setFieldValue('listingTags', uniqueTags);
        }}
        onBlur={handleCreateFormik.handleBlur}
        filterOptions={(options: ITagData[], params: FilterOptionsState<ITagData>) => {
          const { inputValue } = params;
          const filtered = options.filter((option) =>
            _.includes(option.name!.toLowerCase(), inputValue.toLowerCase())
          );

          if (filtered.length === 0 && inputValue !== '') {
            setIsTagNoOptionsOpen(true);
            return [{ name: '', _id: '', color: '' }];
          }
          setIsTagNoOptionsOpen(false);
          return filtered;
        }}
        selectOnFocus
        onBlurCapture={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        clearOnBlur
        blurOnSelect={false}
        handleHomeEndKeys
        options={tagsOptions}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          return option.name || '';
        }}
        renderOption={(props, option) => {
          if (isTagNoOptionsOpen) {
            return renderNoOptions('tag');
          }
          const { ...optionProps } = props;
          return (
            <li
              style={{
                ...(isTagSelected(option) && {
                  backgroundColor:
                    isTagInEditMode || isTagInCreateMode
                      ? 'transparent'
                      : 'rgba(32, 101, 209, 0.16)',
                }),
              }}
              key={option._id!}
              {...optionProps}
            >
              {renderTagMenuItem(option)}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            multiline
            size="medium"
            sx={{
              '& .MuiInputLabel-root:not(.Mui-focused)': {
                color: '#919EAB !important',
                fontSize: '16px !important',
                fontWeight: 400,
              },
              '& .MuiInputBase-input::placeholder': {
                color: '#919EAB !important',
              },
              fontSize: '14px !important',
            }}
            label={'Select tags'}
            placeholder={'Select tags'}
          />
        )}
        renderTags={(selected, getTagProps) =>
          selected.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              key={option._id || option.name}
              label={option.name}
              onDelete={() => handleTagUnselect(option._id!)}
              size="small"
              color={getTagColor(option.name!) || ('default' as LabelColor)}
              variant="soft"
              sx={{
                fontWeight: 700,
                fontSize: '12px !important',
                borderRadius: '8px',
                cursor: 'pointer',
                transition: 'none',
                ...(isTagInEditMode
                  ? {
                      color: '#919EAB',
                      backgroundColor: 'rgba(145, 158, 171, 0.16)',
                    }
                  : {}),
                ...(isTagInEditMode && {
                  '& .MuiChip-deleteIcon': {
                    color: '#919EAB',
                  },
                }),
              }}
            />
          ))
        }
        sx={{
          '& .MuiInputLabel-root': {
            fontWeight: 600,
            color: '#212B36',
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#212B36',
          },
          '& .MuiInputLabel-root:not(.Mui-focused)': {
            color: '#637381 !important',
          },
          '& .MuiInputBase-input': {
            fontSize: '14px !important',
            fontWeight: 400,
            lineHeight: '22px',
          },
          '& .MuiInputBase-root': {
            minHeight: '56px',
          },
        }}
        slotProps={{
          paper: {
            sx: {
              '& .MuiAutocomplete-noOptions': {
                height: '94px',
                overflow: 'hidden',
                padding: '4px 4px 8px 4px',
              },
            },
          },
        }}
      />
    );

  const renderNotes = () =>
    createListingLoading ? (
      <Skeleton variant="rounded" sx={{ width: '100%', height: '150px' }} />
    ) : (
      <TextField
        name="notes"
        label="Internal Note"
        value={handleCreateFormik.values.notes}
        onChange={handleFieldChange('notes')}
        onBlur={handleCreateFormik.handleBlur}
        multiline
        minRows={4.75}
        maxRows={4.75}
      />
    );

  const renderSku = () =>
    createListingLoading ? (
      <Skeleton variant="rounded" sx={{ width: '100%', height: '56px' }} />
    ) : (
      <TextField
        name="sku"
        label="SKU"
        value={handleCreateFormik.values.sku}
        onChange={handleFieldChange('sku')}
        onBlur={handleCreateFormik.handleBlur}
      />
    );

  return (
    <form onSubmit={handleCreateFormik.handleSubmit}>
      <Grid item xs={16}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {createListingLoading ? (
              <Card sx={{ p: 3 }}>
                <Stack spacing={2}>
                  <Skeleton variant="text" width={200} height={30} />
                  <Skeleton variant="text" width={300} height={24} />
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, borderRadius: 2 }}>
                    {[...Array(4)].map((_, index) => (
                      <Skeleton key={index} variant="rectangular" width={150} height={150} />
                    ))}
                  </Box>
                </Stack>
              </Card>
            ) : (
              <ImageUploadGrid type={ImageUploadGridTypeEnum.CREATE} />
            )}
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" sx={{ color: 'text.primary', mt: 2 }}>
              Item Details
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ fontSize: '13px' }}>
              Fill in the required fields marked with an asterisk (*). Other fields are optional but
              recommended for better listing quality.
            </Typography>
          </Grid>

          <Grid item xs={12} md={3.9}>
            <Card sx={{ p: 3 }}>
              <Box
                rowGap={3}
                columnGap={2}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {renderSku()}
                {renderStatus()}
                {renderGroups()}
                {renderTags()}
                {renderNotes()}
              </Box>
            </Card>
          </Grid>

          <CreateListingDetailsForm formik={handleCreateFormik} />
        </Grid>
      </Grid>

      <TagEditPopover
        isFromEditPage
        handleTagsUpdated={handleTagsUpdated}
        ref={tagEditPopoverRef}
      />
      <TagsDeleteDialog handleTagsUpdated={handleTagsUpdated} />
      <GroupEditPopover
        isFromEditPage
        setSelectedGroupValue={setSelectedGroupValue}
        handleGroupSelect={handleGroupSelect}
        selectedGroupToAdd={handleCreateFormik.values.groups?.name ?? ''}
        onPopoverClose={handlePopoverClose}
        onPopoverOpen={handlePopoverOpen}
      />
      <GroupsDeleteDialog
        inputValue={handleCreateFormik.values.groups?.name ?? ''}
        setInputValue={() => handleCreateFormik.setFieldValue('groups', '')}
      />
    </form>
  );
}
