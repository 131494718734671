import { Card, Grid, Stack, TextField, Skeleton, InputAdornment } from '@mui/material';
import { FormikProps } from 'formik';
import { IInitialValues } from './ListingItemEditForm';
import { useSelector } from 'react-redux';
import { IRootState } from 'src/store';

interface EditListingDetailsFormProps {
  formik: FormikProps<IInitialValues>;
  updateSaveButtonState: (values: IInitialValues) => void;
}

export default function EditListingDetailsForm({ formik, updateSaveButtonState }: EditListingDetailsFormProps) {
  const { loading } = useSelector((state: IRootState) => state.inventory);

  const handleChange = (field: 'description') => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    formik.setFieldValue(field, value);
    formik.setFieldTouched(field, true);
    updateSaveButtonState({
      ...formik.values,
      [field]: value,
    });
  };

  // For title field
  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= 80) {
      formik.setFieldValue('title', value);
      formik.setFieldTouched('title', true);
      updateSaveButtonState({
        ...formik.values,
        title: value,
      });
    }
  };

  // For price field
  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === '' || (Number(value) >= 0 && !value.includes('-'))) {
      const newPrice = value === '' ? null : Number(value);
      formik.setFieldValue('price', newPrice);
      formik.setFieldTouched('price', true);
      updateSaveButtonState({
        ...formik.values,
        price: newPrice,
      });
    }
  };

  const renderSkeleton = (height: number = 56) => (
    <Skeleton variant="rounded" sx={{ width: '100%', height: `${height}px` }} />
  );

  // Check if fields should be editable based on status
  const isEditable = formik.values.status === 'Draft';

  return (
    <Grid item xs={12} md={12} width={'100%'}>
      <Card sx={{ p: 3 }}>
        <Stack spacing={3}>
          {loading ? (
            renderSkeleton()
          ) : (
            <TextField
              name="title"
              label="Title"
              required
              value={formik.values.title}
              onChange={handleTitleChange}
              onBlur={(e) => {
                formik.setFieldValue('title', e.target.value.trim());
                formik.handleBlur(e);
                updateSaveButtonState({
                  ...formik.values,
                  title: e.target.value.trim(),
                });
              }}
              error={formik.touched.title && !formik.values.title.trim()}
              helperText={
                formik.touched.title && !formik.values.title.trim()
                  ? 'Title is required'
                  : '⚡ Pro Tip: Include keywords that buyers would use to search for your item'
              }
              inputProps={{
                maxLength: 80,
              }}
              disabled={!isEditable}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{formik.values.title.length}/80</InputAdornment>
                ),
              }}
            />
          )}

          {loading ? (
            renderSkeleton(200)
          ) : (
            <TextField
              name="description"
              label="Description (Optional)"
              multiline
              minRows={8}
              maxRows={8}
              value={formik.values.description}
              onChange={handleChange('description')}
              onBlur={formik.handleBlur}
              disabled={!isEditable}
            />
          )}

          {loading ? (
            renderSkeleton()
          ) : (
            <TextField
              name="price"
              label="Price (Optional)"
              type="number"
              value={formik.values.price}
              onChange={handlePriceChange}
              onBlur={formik.handleBlur}
              disabled={!isEditable}
              inputProps={{
                min: 0,
                step: '0.01',
                pattern: '[0-9]*',
                onKeyPress: (e) => {
                  if (e.key === '-') {
                    e.preventDefault();
                  }
                },
              }}
            />
          )}
        </Stack>
      </Card>
    </Grid>
  );
}
