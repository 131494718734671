import { Marketplace } from '@beta.limited/primelister';

export interface ListingReference {
  id: string;
  listingLink: string;
  platform: string;
  added: number;
  updated: number;
  closetName?: string;
}

export enum ListingStatus {
  Listed = 'Listed',
  Sold = 'Sold',
  Draft = 'Draft',
}

export interface CreateNewListingInput {
  title: string;
  description?: string;
  price?: number;
  images?: string[];
  status?: ListingStatus;
  refs: { marketplace: Marketplace; reference: ListingReference }[];
  notes?: string;
  sku?: string;
  tags?: string[];
  groupId?: string;
}
