export interface ImageItem {
  id: string;
  url: string;
  name: string;
  orderIndex: number;
  file?: File;
  isSelected?: boolean;
  type: 'image' | 'video';
  thumbnailUrl?: string;
}

export const convertToAbsoluteUrl = (url: string) => {
    if (!url) return url;
  
    // If it's a blob URL or data URL (local file), return as is
    if (url.startsWith('blob:') || url.startsWith('data:')) {
      return url;
    }
  
    // First ensure the URL is absolute
    let absoluteUrl = url;
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      absoluteUrl = `https://${url}`;
    }
  
    return absoluteUrl;
  };

export const generateVideoThumbnail = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.playsInline = true;
    video.muted = true;

    const timeoutId = setTimeout(() => {
      reject(new Error('Video loading timeout'));
    }, 5000); // 5 second timeout

    video.onloadeddata = () => {
      clearTimeout(timeoutId);
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth || 300;
      canvas.height = video.videoHeight || 300;
      const ctx = canvas.getContext('2d');

      video.currentTime = 1;

      video.onseeked = () => {
        if (ctx) {
          try {
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            const thumbnailUrl = canvas.toDataURL('image/jpeg');
            URL.revokeObjectURL(video.src); // Clean up
            resolve(thumbnailUrl);
          } catch (error) {
            reject(error);
          }
        } else {
          reject(new Error('Could not get canvas context'));
        }
      };
    };

    video.onerror = (error) => {
      clearTimeout(timeoutId);
      URL.revokeObjectURL(video.src); // Clean up
      reject(new Error(`Video loading error: ${error}`));
    };

    video.src = URL.createObjectURL(file);
  });
};

export const checkVideoDuration = (file: File): Promise<boolean> => {
  return new Promise((resolve) => {
    const video = document.createElement('video');
    video.preload = 'metadata';

    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      const duration = video.duration;
      resolve(duration >= 5 && duration <= 15);
    };

    video.src = URL.createObjectURL(file);
  });
};

export const processInitialAssets = async (
  initialAssets: Array<{ url: string; name: string; orderIndex: number }> | undefined,
  hasLoadedInitialAssets: boolean
): Promise<ImageItem[]> => {
  if (!initialAssets || initialAssets.length === 0 || hasLoadedInitialAssets) {
    return [];
  }

  const processedAssets = await Promise.all(
    initialAssets.map(async (asset): Promise<ImageItem> => {
      const isVideo = asset.url.toLowerCase().match(/\.(mp4|webm|ogg)$/i) !== null;

      if (isVideo) {
        let thumbnailUrl =
          'https://primelister-files.s3.us-west-1.amazonaws.com/image_thumbnail.svg';

        try {
          // Create a temporary video element to generate thumbnail
          const video = document.createElement('video');
          video.crossOrigin = 'anonymous'; // Add this for CORS

          // Create a promise that resolves with the video thumbnail
          thumbnailUrl = await new Promise<string>((resolve, reject) => {
            const timeoutId = setTimeout(() => {
              reject(new Error('Video loading timeout'));
            }, 5000);

            video.onloadeddata = () => {
              clearTimeout(timeoutId);
              const canvas = document.createElement('canvas');
              canvas.width = video.videoWidth || 300;
              canvas.height = video.videoHeight || 300;
              const ctx = canvas.getContext('2d');

              video.currentTime = 1;

              video.onseeked = () => {
                if (ctx) {
                  try {
                    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
                    const thumbnailUrl = canvas.toDataURL('image/jpeg');
                    resolve(thumbnailUrl);
                  } catch (error) {
                    reject(error);
                  }
                } else {
                  reject(new Error('Could not get canvas context'));
                }
              };
            };

            video.onerror = () => {
              clearTimeout(timeoutId);
              reject(new Error('Error loading video'));
            };

            video.src = asset.url;
          });
        } catch (error) {
          console.error('Error generating thumbnail:', error);
        }

        return {
          id: `initial-${asset.name}`,
          url: asset.url,
          name: asset.name,
          orderIndex: asset.orderIndex,
          type: 'video',
          thumbnailUrl,
        };
      }

      return {
        id: `initial-${asset.name}`,
        url: asset.url,
        name: asset.name,
        orderIndex: asset.orderIndex,
        type: 'image',
      };
    })
  );

  return [...processedAssets].sort((a, b) => a.orderIndex - b.orderIndex);
}; 