import { Typography } from '@mui/material';
import { DataGridPriceColumnInterface } from 'src/pages/dashboard/Inventory/types/dataGridInterfaces';

export default function DataGridPriceColumn({ price }: DataGridPriceColumnInterface) {
  return price ? (
    <Typography variant="subtitle2">${price}</Typography>
  ) : (
    <Typography variant="subtitle2">—</Typography>
  );
}
