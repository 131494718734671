import { Card, Grid, Stack, TextField, Collapse, Button, Skeleton, InputAdornment } from '@mui/material';
import { FormikProps } from 'formik';
import { IInitialValues } from './CreateListingForm';
import { useState } from 'react';
import Iconify from 'src/components/iconify';
import { useSelector } from 'react-redux';
import { IRootState } from 'src/store';

interface CreateListingDetailsFormProps {
  formik: FormikProps<IInitialValues>;
}

export default function CreateListingDetailsForm({ formik }: CreateListingDetailsFormProps) {
  const [isAdditionalSettingsOpen, setIsAdditionalSettingsOpen] = useState(false);
  const { createListingLoading } = useSelector((state: IRootState) => state.inventory);

  const handleChange = (field: keyof IInitialValues) => (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue(field.toString(), event.target.value);
  };

  const renderSkeleton = (height: number = 56) => (
    <Skeleton variant="rounded" sx={{ width: '100%', height: `${height}px` }} />
  );

  return (
    <Grid item xs={12} md={8.1}>
      <Card sx={{ p: 3 }}>
        <Stack spacing={3}>
          {createListingLoading ? (
            renderSkeleton()
          ) : (
            <TextField
              name="title"
              label="Title"
              required
              value={formik.values.title}
              onChange={(e) => {
                const value = e.target.value;
                if (value.length <= 80) {
                  formik.setFieldValue('title', value);
                }
              }}
              onBlur={(e) => {
                formik.setFieldValue('title', e.target.value.trim());
                formik.handleBlur(e);
              }}
              error={formik.touched.title && !formik.values.title.trim()}
              helperText={
                formik.touched.title && !formik.values.title.trim()
                  ? 'Title is required'
                  : '⚡ Pro Tip: Include keywords that buyers would use to search for your item'
              }
              inputProps={{
                maxLength: 80
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {formik.values.title.length}/80
                  </InputAdornment>
                ),
              }}
            />
          )}

          {createListingLoading ? (
            renderSkeleton(200)
          ) : (
            <TextField
              name="description"
              label="Description (Optional)"
              multiline
              minRows={8}
              maxRows={8}
              value={formik.values.description}
              onChange={handleChange('description')}
              onBlur={formik.handleBlur}
            />
          )}

          <Stack spacing={2}>
            {createListingLoading ? (
              renderSkeleton()
            ) : (
              <TextField
                name="price"
                label="Price (Optional)"
                type="number"
                value={formik.values.price}
                onChange={(e) => {
                  const value = e.target.value;
                  // Only allow positive numbers
                  if (value === '' || (Number(value) >= 0 && !value.includes('-'))) {
                    formik.setFieldValue('price', value);
                  }
                }}
                onBlur={formik.handleBlur}
                inputProps={{ 
                  min: 0,
                  step: "0.01",
                  pattern: "[0-9]*",
                  onKeyPress: (e) => {
                    if (e.key === '-') {
                      e.preventDefault();
                    }
                  }
                }}
              />
            )}
{/* 
            <Button
              onClick={() => setIsAdditionalSettingsOpen(!isAdditionalSettingsOpen)}
              startIcon={
                <Iconify
                  icon={isAdditionalSettingsOpen ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                />
              }
              sx={{
                justifyContent: 'flex-start',
                color: 'text.secondary',
                '&:hover': { backgroundColor: 'transparent' },
              }}
            >
              Additional Settings
            </Button>

            <Collapse in={isAdditionalSettingsOpen}>
              <Stack spacing={2}>
                {createListingLoading ? (
                  <>
                    {[...Array(10)].map((_, index) => (
                      <Skeleton key={index} variant="rounded" sx={{ width: '100%', height: '56px' }} />
                    ))}
                  </>
                ) : (
                  <>
                    <TextField
                      name="quantity"
                      label="Quantity"
                      type="number"
                      value={formik.values.quantity}
                      onChange={handleChange('quantity')}
                      onBlur={formik.handleBlur}
                      inputProps={{ min: 1 }}
                    />

                    <TextField
                      name="condition"
                      label="Condition"
                      value={formik.values.condition}
                      onChange={handleChange('condition')}
                      onBlur={formik.handleBlur}
                    />

                    <TextField
                      name="brand"
                      label="Brand"
                      value={formik.values.brand}
                      onChange={handleChange('brand')}
                      onBlur={formik.handleBlur}
                    />

                    <TextField
                      name="color"
                      label="Color"
                      value={formik.values.color}
                      onChange={handleChange('color')}
                      onBlur={formik.handleBlur}
                    />

                    <TextField
                      name="size"
                      label="Size"
                      value={formik.values.size}
                      onChange={handleChange('size')}
                      onBlur={formik.handleBlur}
                    />

                    <TextField
                      name="material"
                      label="Material"
                      value={formik.values.material}
                      onChange={handleChange('material')}
                      onBlur={formik.handleBlur}
                    />

                    <TextField
                      name="style"
                      label="Style"
                      value={formik.values.style}
                      onChange={handleChange('style')}
                      onBlur={formik.handleBlur}
                    />

                    <TextField
                      name="pattern"
                      label="Pattern"
                      value={formik.values.pattern}
                      onChange={handleChange('pattern')}
                      onBlur={formik.handleBlur}
                    />

                    <TextField
                      name="category"
                      label="Category"
                      value={formik.values.category}
                      onChange={handleChange('category')}
                      onBlur={formik.handleBlur}
                    />

                    <TextField
                      name="subcategory"
                      label="Subcategory"
                      value={formik.values.subcategory}
                      onChange={handleChange('subcategory')}
                      onBlur={formik.handleBlur}
                    />
                  </>
                )}
              </Stack>
            </Collapse> */}
          </Stack>
        </Stack>
      </Card>
    </Grid>
  );
} 