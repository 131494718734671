import { useState, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Stack,
  IconButton,
  Typography,
} from '@mui/material';
import { Cropper } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import Iconify from 'src/components/iconify';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify';
import { convertToAbsoluteUrl } from './utils/imageProcessingUtils';

interface ImageEditDialogProps {
  open: boolean;
  onClose: () => void;
  images: Array<{ id: string; url: string; type?: 'image' | 'video' }>;
  onSave: (editedImages: Array<{ id: string; url: string }>) => void;
  initialImageId?: string;
}

const StyledImagePreview = styled(Box)(({ theme }) => ({
  width: 100,
  height: 100,
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  cursor: 'pointer',
  position: 'relative',
  '&:hover': {
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(22, 28, 36, 0.64)',
    },
  },
  '&.selected': {
    border: `2px solid ${theme.palette.primary.main}`,
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(22, 28, 36, 0.64)',
    },
  },
}));

export default function ImageEditDialog({
  open,
  onClose,
  images,
  onSave,
  initialImageId,
}: ImageEditDialogProps) {
  const [selectedImageIndex, setSelectedImageIndex] = useState(() => {
    const initialIndex = images.findIndex((img) => img.id === initialImageId);
    return initialIndex >= 0 ? initialIndex : 0;
  });
  
  const cropperRef = useRef<Cropper>();

  const handleRotateLeft = () => {
    if (cropperRef.current) {
      cropperRef.current.rotate(-90);
    }
  };

  const handleRotateRight = () => {
    if (cropperRef.current) {
      cropperRef.current.rotate(90);
    }
  };

  const handleZoomIn = () => {
    if (cropperRef.current) {
      cropperRef.current.zoom(0.1);
    }
  };

  const handleZoomOut = () => {
    if (cropperRef.current) {
      cropperRef.current.zoom(-0.1);
    }
  };

  const handleReset = () => {
    if (cropperRef.current) {
      cropperRef.current.reset();
    }
  };

  const handleSave = () => {
    if (cropperRef.current) {
      try {
        const croppedCanvas = cropperRef.current.getCroppedCanvas();
        if (croppedCanvas) {
          const croppedImage = croppedCanvas.toDataURL();
          const editedImages = [...images];
          editedImages[selectedImageIndex] = {
            ...images[selectedImageIndex],
            url: croppedImage,
          };
          onSave(editedImages);
        }
      } catch (error) {
        console.error('Error saving image:', error);
        toast.error('Unable to edit this image due to security restrictions. Please try downloading and re-uploading the image.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">Edit images</Typography>
          <IconButton onClick={onClose} size="small">
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent sx={{ height: 600, p: 3 }}>
        <Stack direction="row" spacing={3} sx={{ height: '100%' }}>
          {/* Left sidebar with image thumbnails */}
          <Stack spacing={1} sx={{ 
            width: 120,
            maxHeight: '100%',
            overflowY: 'auto'
          }}>
            {images.filter(image => image.type !== 'video').map((image, index) => (
              <StyledImagePreview
                key={image.id}
                className={selectedImageIndex === index ? 'selected' : ''}
                onClick={() => setSelectedImageIndex(index)}
              >
                <Box
                  component="img"
                  src={convertToAbsoluteUrl(image.url)}
                  sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              </StyledImagePreview>
            ))}
          </Stack>

          {/* Main editing area */}
          <Box
            sx={{
              flex: 1,
              position: 'relative',
              bgcolor: 'background.neutral',
              borderRadius: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              p: 2,
              '& .cropper-container': {
                width: '100% !important',
                height: '100% !important',
              },
            }}
          >
            <Cropper
              src={convertToAbsoluteUrl(images[selectedImageIndex]?.url)}
              style={{ height: '100%', width: '100%' }}
              initialAspectRatio={1}
              guides={true}
              viewMode={1}
              dragMode="move"
              crossOrigin="anonymous"
              checkCrossOrigin={true}
              autoCropArea={1}
              background={false}
              responsive={true}
              checkOrientation={false}
              onInitialized={(instance) => {
                if (instance) {
                  cropperRef.current = instance;
                }
              }}
            />

            {/* Editing controls */}
            <Stack
              direction="row"
              spacing={2}
              sx={{
                position: 'absolute',
                bottom: 16,
                left: '50%',
                transform: 'translateX(-50%)',
                bgcolor: 'background.paper',
                borderRadius: 1,
                boxShadow: (theme) => theme.shadows[10],
                p: 1,
                zIndex: 1,
              }}
            >
              <IconButton onClick={handleZoomOut}>
                <Iconify icon="mdi:minus-circle-outline" />
              </IconButton>
              <IconButton onClick={handleZoomIn}>
                <Iconify icon="mdi:plus-circle-outline" />
              </IconButton>
              <IconButton onClick={handleRotateLeft}>
                <Iconify icon="mdi:rotate-left" />
              </IconButton>
              <IconButton onClick={handleRotateRight}>
                <Iconify icon="mdi:rotate-right" />
              </IconButton>
              <IconButton onClick={handleReset}>
                <Iconify icon="mdi:refresh" />
              </IconButton>
            </Stack>
          </Box>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ px: 3, pb: 3 }}>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSave}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}
