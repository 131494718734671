import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Stack,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  InputAdornment,
  Switch,
  useMediaQuery,
} from '@mui/material';
import { DesktopDateTimePicker, MobileDateTimePicker } from '@mui/x-date-pickers';
import CommonTitleTooltip from './shared/CommonTitleTooltip';
import useResponsive from 'src/hooks/useResponsive';
import CustomDateTimePicker from './shared/CustomDateTimePicker';
import { commonDateTimePickerStyles } from '../CreateNewTaskDialog';
import { getCurrencyInfo } from 'src/pages/automations/AutomationsPage/helpers/getCurrencyInfo';
import { IRootState } from 'src/store';
import { validatePositiveNumber } from '../../helpers/validation';
import { DiscountUnit, ShippingOfferType } from '@beta.limited/primelister';
import { ISendOfferShippingOffer } from '../../types/bulk-action';
import ScheduleOption from './shared/ScheduleOption';

interface SendOfferDialogContentProps {
  isScheduleEnabled: boolean;
  setIsScheduleEnabled: (value: boolean) => void;
  discountPercentage: number;
  setDiscountPercentage: (value: number) => void;
  selectedDateTime: Date | null;
  setSelectedDateTime: (date: Date | null) => void;
  shippingOffer: ISendOfferShippingOffer;
  setShippingOffer: (value: ISendOfferShippingOffer) => void;
  minEarningType: DiscountUnit;
  setMinEarningType: (value: DiscountUnit) => void;
  isMinNetEarningEnabled: boolean;
  setIsMinNetEarningEnabled: (value: boolean) => void;
  minThreshold: number | string;
  setMinThreshold: (value: number | string) => void;
  setIsMinThresholdValid: (value: boolean) => void;
  setIsDiscountPercentageValid: (value: boolean) => void;
}

export default function SendOfferDialogContent({
  isScheduleEnabled,
  setIsScheduleEnabled,
  discountPercentage,
  setDiscountPercentage,
  selectedDateTime,
  setSelectedDateTime,
  isMinNetEarningEnabled,
  setIsMinNetEarningEnabled,
  minEarningType,
  setMinEarningType,
  minThreshold,
  shippingOffer,
  setShippingOffer,
  setMinThreshold,
  setIsDiscountPercentageValid,
  setIsMinThresholdValid,
}: SendOfferDialogContentProps) {
  const dispatch = useDispatch();
  const isMobileScreen = useResponsive('down', 'sm');
  const isSmallerMobileScreen = useMediaQuery('(max-width: 321px)');
  const { activeClosetAutomationPlatform } = useSelector((state: IRootState) => state.automations);

  const { currency, currencySymbol } = getCurrencyInfo(activeClosetAutomationPlatform);

  const shippingOfferOptions: { label: string; value: ISendOfferShippingOffer }[] =
    currency === 'USD'
      ? [
          {
            label: 'Original Shipping Cost',
            value: {
              type: ShippingOfferType.ORIGINAL_COST,
            },
          },
          {
            label: 'No Discount',
            value: {
              type: ShippingOfferType.NO_DISCOUNT,
            },
          },
          {
            label: '$5.95',
            value: {
              type: ShippingOfferType.CUSTOM,
              cost: 5.95,
            },
          },
          {
            label: '$4.99',
            value: {
              type: ShippingOfferType.CUSTOM,
              cost: 4.99,
            },
          },
          {
            label: 'Free',
            value: {
              type: ShippingOfferType.FREE,
            },
          },
        ]
      : [
          {
            label: 'Original Shipping Cost',
            value: {
              type: ShippingOfferType.ORIGINAL_COST,
            },
          },
          {
            label: 'No Discount',
            value: {
              type: ShippingOfferType.NO_DISCOUNT,
            },
          },
          {
            label: 'C$9.99',
            value: {
              type: ShippingOfferType.CUSTOM,
              cost: 9.99,
            },
          },
          {
            label: 'C$6.99',
            value: {
              type: ShippingOfferType.CUSTOM,
              cost: 6.99,
            },
          },
          {
            label: 'Free',
            value: {
              type: ShippingOfferType.FREE,
            },
          },
        ];

  const earningTypeOptions = [
    { label: currency !== 'USD' ? 'Amount (C$)' : 'Amount ($)', value: DiscountUnit.FIXED },
    { label: 'Percentage (%)', value: DiscountUnit.PERCENTAGE },
  ];

  const validationSchema = Yup.object().shape({
    discountPercentage: Yup.number()
      .required('Enter a whole number between 10 and 80.')
      .min(10, 'Enter a whole number between 10 and 80.')
      .max(80, 'Enter a whole number between 10 and 80.'),
    minThreshold: Yup.number().when('minEarningType', {
      is: DiscountUnit.FIXED,
      then: Yup.number()
        .required('Enter a whole number between 0 and 9999.')
        .min(0, 'Enter a whole number between 0 and 9999.')
        .max(9999, 'Enter a whole number between 0 and 9999.'),
      otherwise: Yup.number()
        .required('Enter a whole number between 20 and 90.')
        .min(20, 'Enter a whole number between 20 and 90.')
        .max(90, 'Enter a whole number between 20 and 90.'),
    }),
  });

  const handleOfferValueFormik = useFormik({
    initialValues: {
      shippingOffer,
      minEarningType,
      minThreshold: '',
      isMinNetEarningEnabled,
      discountPercentage,
    },
    validationSchema,
    onSubmit: () => {},
  });

  const getAdornment = () => {
    const symbol =
      handleOfferValueFormik.values.minEarningType === DiscountUnit.PERCENTAGE
        ? '%'
        : currencySymbol;
    return `${symbol}`;
  };

  return (
    <Box
      sx={{
        overflowX: 'hidden',
      }}
    >
      <Stack
        gap={2}
        sx={{
          marginTop: '8px',
        }}
      >
        <Box>
          <Stack>
            <Typography
              variant="subtitle1"
              noWrap
              sx={{
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '22px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Discount Percentage
              <CommonTitleTooltip title="Enter the percentage to discount the listing price when sending the offer." />
            </Typography>

            <Box>
              <TextField
                placeholder="Enter Value"
                size="small"
                sx={{
                  maxWidth: '164px',
                  height: '40px',
                  mt: '8px',
                  '& .MuiInputBase-input::placeholder': {
                    fontSize: '14px',
                  },
                }}
                name="discountPercentage"
                value={handleOfferValueFormik.values.discountPercentage}
                onChange={(e) => {
                  const { value } = e.target;
                  // Allow only positive numbers
                  if (validatePositiveNumber(value)) {
                    handleOfferValueFormik.handleChange(e);
                    setIsDiscountPercentageValid(handleOfferValueFormik.isValid);
                    setDiscountPercentage(Number(value));
                  }
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">%</InputAdornment>,
                }}
                onBlur={handleOfferValueFormik.handleBlur}
                error={Boolean(
                  handleOfferValueFormik.touched.discountPercentage &&
                    handleOfferValueFormik.errors.discountPercentage
                )}
                helperText={
                  handleOfferValueFormik.touched.discountPercentage &&
                  handleOfferValueFormik.errors.discountPercentage
                }
                FormHelperTextProps={{
                  sx: {
                    whiteSpace: 'nowrap',
                  },
                }}
              />
            </Box>
          </Stack>
        </Box>

        <Box
          sx={{
            marginTop: '8px',
          }}
        >
          <Box>
            <Stack
              sx={{
                marginBottom: '8px',
              }}
              direction="row"
              justifyContent="space-between"
            >
              <Typography
                variant="subtitle1"
                noWrap
                sx={{
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '22px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                Shipping Offer
                <CommonTitleTooltip title="Choose the shipping price you want to offer." />
              </Typography>
            </Stack>
          </Box>

          <RadioGroup
            row
            name="shippingOffer"
            value={JSON.stringify(handleOfferValueFormik.values.shippingOffer)}
            onChange={(e) => {
              const selectedOption = JSON.parse(e.target.value);
              handleOfferValueFormik.setFieldValue('shippingOffer', selectedOption);
              setShippingOffer(selectedOption as ISendOfferShippingOffer);
            }}
          >
            {shippingOfferOptions.map((option) => (
              <FormControlLabel
                key={option.label}
                value={JSON.stringify(option.value)}
                control={<Radio />}
                label={option.label}
                sx={{
                  '&:nth-of-type(1)': {
                    marginRight: '16px',
                  },
                  '&:nth-of-type(2)': {
                    marginRight: '0',
                  },
                  marginLeft: 0,
                }}
              />
            ))}
          </RadioGroup>
        </Box>

        <Typography
          noWrap
          sx={{
            fontSize: '18px',
            fontWeight: 700,
            lineHeight: '26px',
            marginTop: '8px',
            marginBottom: '-8px',
          }}
        >
          Options
        </Typography>

        <Box>
          <Stack sx={{}} direction="row" justifyContent="space-between">
            <Typography
              variant="subtitle1"
              noWrap
              sx={{
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '22px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Check Minimum Net Earnings
              <CommonTitleTooltip title="Enable this option to send offers to likers only when the discounted listing price meets your specified minimum net earning." />
            </Typography>
            <Switch
              checked={isMinNetEarningEnabled}
              onChange={(e) => setIsMinNetEarningEnabled(e.target.checked)}
              sx={{
                marginRight: '-10px',
              }}
            />
          </Stack>

          {isMinNetEarningEnabled && (
            <>
              <Box sx={{ marginBottom: '16px' }}>
                <Stack
                  sx={{
                    marginTop: '24px !important',
                    marginBottom: '8px',
                  }}
                  direction="row"
                  justifyContent="space-between"
                >
                  <Typography
                    variant="subtitle1"
                    noWrap
                    sx={{
                      fontSize: '14px',
                      fontWeight: 600,
                      lineHeight: '22px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    Earning Type
                    <CommonTitleTooltip title="Set a minimum net earning threshold as a specific amount or a percentage of the original listing price." />
                  </Typography>
                </Stack>

                <RadioGroup
                  row
                  name="minEarningType"
                  sx={{
                    flexWrap: 'nowrap',
                  }}
                  value={handleOfferValueFormik.values.minEarningType}
                  onChange={(e) => {
                    handleOfferValueFormik.setFieldValue('minEarningType', e.target.value);
                    setMinEarningType(e.target.value as DiscountUnit);
                  }}
                >
                  {earningTypeOptions.map((option) => (
                    <FormControlLabel
                      key={option.label}
                      value={option.value}
                      control={<Radio />}
                      label={option.label}
                      sx={{
                        '&:nth-of-type(1)': {
                          marginRight: '16px',
                        },
                        '&:nth-of-type(2)': {
                          marginRight: '0',
                        },
                        marginLeft: 0,
                      }}
                    />
                  ))}
                </RadioGroup>
              </Box>

              <Box>
                <Stack>
                  <Typography
                    variant="subtitle1"
                    noWrap
                    sx={{
                      fontSize: '14px',
                      fontWeight: 600,
                      lineHeight: '22px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    Min. Threshold
                    <CommonTitleTooltip title="Enter the minimum net earning threshold." />
                  </Typography>

                  <TextField
                    placeholder="Enter Value"
                    size="small"
                    name="minThreshold"
                    value={handleOfferValueFormik.values.minThreshold}
                    onChange={(e) => {
                      const { value } = e.target;
                      // Allow only positive numbers
                      if (validatePositiveNumber(value)) {
                        handleOfferValueFormik.handleChange(e);
                        setIsMinThresholdValid(true); // Set to true when valid
                        setMinThreshold(value === '' ? '' : Number(value));
                      } else {
                        setIsMinThresholdValid(false); // Set to false when invalid
                      }
                    }}
                    onBlur={handleOfferValueFormik.handleBlur}
                    error={Boolean(
                      handleOfferValueFormik.touched.minThreshold &&
                        handleOfferValueFormik.errors.minThreshold
                    )}
                    helperText={
                      handleOfferValueFormik.touched.minThreshold &&
                      handleOfferValueFormik.errors.minThreshold
                    }
                    FormHelperTextProps={{
                      sx: {
                        whiteSpace: 'nowrap',
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">{getAdornment()}</InputAdornment>
                      ),
                    }}
                    sx={{
                      maxWidth: '164px',
                      mt: '8px',
                      height: '40px',
                      '& .MuiInputBase-input::placeholder': {
                        fontSize: '14px',
                      },
                    }}
                  />
                </Stack>
              </Box>
            </>
          )}
        </Box>

        <ScheduleOption
          isScheduleEnabled={isScheduleEnabled}
          setIsScheduleEnabled={setIsScheduleEnabled}
          selectedDateTime={selectedDateTime}
          setSelectedDateTime={setSelectedDateTime}
          tooltipTitle="Select a date and time to schedule the sending of offers."
        />
      </Stack>
    </Box>
  );
}
