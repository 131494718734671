// @mui
import { Box, Skeleton, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { IRootState } from 'src/store';
// hooks

import PlatfromLinksForm from './PlatformLinksForm';
import { IPlatforms } from 'src/store/dashboard/slices/myShopsSlice';
import { AutomationPlatform } from 'src/hooks/useAutomationPlatform';
import { getMarketplaceDisplayName, Marketplace } from '@beta.limited/primelister';
import { ConnectionStatus } from 'src/store/automations/slices/myClosetSlice';

export enum PlatformLinksMode {
  EDIT = 'edit',
  CREATE = 'create',
}

const platformIcons = [
  {
    name: IPlatforms.DEPOP,
    url: '/icons/editLinkIcons/depop.svg',
    displayName: getMarketplaceDisplayName(IPlatforms.DEPOP as unknown as Marketplace),
    linkValidation: 'depop.com/products/',
  },
  {
    name: IPlatforms.EBAYAU,
    url: '/icons/editLinkIcons/ebay.svg',
    displayName: getMarketplaceDisplayName(IPlatforms.EBAYAU as unknown as Marketplace),
    linkValidation: 'ebay.com.au/itm/',
  },
  {
    name: IPlatforms.EBAYAT,
    url: '/icons/editLinkIcons/ebay.svg',
    displayName: getMarketplaceDisplayName(IPlatforms.EBAYAT as unknown as Marketplace),
    linkValidation: 'ebay.at/itm/',
  },
  {
    name: IPlatforms.EBAYBE,
    url: '/icons/editLinkIcons/ebay.svg',
    displayName: 'eBay (BE)',
    linkValidation: 'ebay.be/itm/',
  },
  {
    name: IPlatforms.EBAYCH,
    url: '/icons/editLinkIcons/ebay.svg',
    displayName: 'eBay (CH)',
    linkValidation: 'ebay.ch/itm/',
  },
  {
    name: IPlatforms.EBAYCA,
    url: '/icons/editLinkIcons/ebay.svg',
    displayName: 'eBay (CA)',
    linkValidation: 'ebay.ca/itm/',
  },
  {
    name: IPlatforms.EBAYDE,
    url: '/icons/editLinkIcons/ebay.svg',
    displayName: 'eBay (DE)',
    linkValidation: 'ebay.de/itm/',
  },
  {
    name: IPlatforms.EBAYES,
    url: '/icons/editLinkIcons/ebay.svg',
    displayName: 'eBay (ES)',
    linkValidation: 'ebay.es/itm/',
  },
  {
    name: IPlatforms.EBAYFR,
    url: '/icons/editLinkIcons/ebay.svg',
    displayName: 'eBay (FR)',
    linkValidation: 'ebay.fr/itm/',
  },
  {
    name: IPlatforms.EBAYGB,
    url: '/icons/editLinkIcons/ebay.svg',
    displayName: 'eBay (GB)',
    linkValidation: 'ebay.co.uk/itm/',
  },
  {
    name: IPlatforms.EBAYHK,
    url: '/icons/editLinkIcons/ebay.svg',
    displayName: 'eBay (HK)',
    linkValidation: 'ebay.com.hk/itm/',
  },
  {
    name: IPlatforms.EBAYIE,
    url: '/icons/editLinkIcons/ebay.svg',
    displayName: 'eBay (IE)',
    linkValidation: 'ebay.ie/itm/',
  },
  {
    name: IPlatforms.EBAYIN,
    url: '/icons/editLinkIcons/ebay.svg',
    displayName: 'eBay (IN)',
    linkValidation: 'ebay.in/itm/',
  },
  {
    name: IPlatforms.EBAYIT,
    url: '/icons/editLinkIcons/ebay.svg',
    displayName: 'eBay (IT)',
    linkValidation: 'ebay.it/itm/',
  },
  {
    name: IPlatforms.EBAYMY,
    url: '/icons/editLinkIcons/ebay.svg',
    displayName: 'eBay (MY)',
    linkValidation: 'ebay.com.my/itm/',
  },
  {
    name: IPlatforms.EBAYNL,
    url: '/icons/editLinkIcons/ebay.svg',
    displayName: 'eBay (NL)',
    linkValidation: 'ebay.nl/itm/',
  },
  {
    name: IPlatforms.EBAYPH,
    url: '/icons/editLinkIcons/ebay.svg',
    displayName: 'eBay (PH)',
    linkValidation: 'ebay.ph/itm/',
  },
  {
    name: IPlatforms.EBAYPL,
    url: '/icons/editLinkIcons/ebay.svg',
    displayName: 'eBay (PL)',
    linkValidation: 'ebay.pl/itm/',
  },
  {
    name: IPlatforms.EBAYSG,
    url: '/icons/editLinkIcons/ebay.svg',
    displayName: 'eBay (SG)',
    linkValidation: 'ebay.com.sg/itm/',
  },
  {
    name: IPlatforms.EBAYTW,
    url: '/icons/editLinkIcons/ebay.svg',
    displayName: 'eBay (TW)',
    linkValidation: 'ebay.com.tw/itm/',
  },
  {
    name: IPlatforms.EBAYVN,
    url: '/icons/editLinkIcons/ebay.svg',
    displayName: 'eBay (VN)',
    linkValidation: 'ebay.vn/itm/',
  },
  {
    name: IPlatforms.EBAYUS,
    url: '/icons/editLinkIcons/ebay.svg',
    displayName: 'eBay (US)',
    linkValidation: 'ebay.com/itm/',
  },
  {
    name: IPlatforms.ETSY,
    url: '/icons/editLinkIcons/etsy.svg',
    displayName: getMarketplaceDisplayName(IPlatforms.ETSY as unknown as Marketplace),
    linkValidation: 'etsy.com/',
  },
  {
    name: IPlatforms.FACEBOOK,
    url: '/icons/editLinkIcons/facebook.svg',
    displayName: getMarketplaceDisplayName(IPlatforms.FACEBOOK as unknown as Marketplace),
    linkValidation: 'facebook.com/marketplace/item/',
  },
  {
    name: IPlatforms.GRAILED,
    url: '/icons/editLinkIcons/grailed.svg',
    displayName: getMarketplaceDisplayName(IPlatforms.GRAILED as unknown as Marketplace),
    linkValidation: 'grailed.com/listings/',
  },
  {
    name: IPlatforms.MERCARI,
    url: '/icons/editLinkIcons/mercari.svg',
    displayName: getMarketplaceDisplayName(IPlatforms.MERCARI as unknown as Marketplace),
    linkValidation: 'mercari.com/us/item/',
  },
  {
    name: IPlatforms.POSHMARKCA,
    url: '/icons/editLinkIcons/poshmark.svg',
    displayName: getMarketplaceDisplayName(IPlatforms.POSHMARKCA as unknown as Marketplace),
    linkValidation: 'poshmark.ca/listing/',
  },
  {
    name: IPlatforms.POSHMARKUS,
    url: '/icons/editLinkIcons/poshmark.svg',
    displayName: getMarketplaceDisplayName(IPlatforms.POSHMARKUS as unknown as Marketplace),
    linkValidation: 'poshmark.com/listing/',
  },
  {
    name: IPlatforms.SHOPIFY,
    url: '/icons/editLinkIcons/shopify.svg',
    displayName: getMarketplaceDisplayName(IPlatforms.SHOPIFY as unknown as Marketplace),
    linkValidation: `admin.shopify.com/store/`,
  },
];

export default function PlatfromLinksContainer({
  mode = PlatformLinksMode.EDIT,
}: {
  mode?: PlatformLinksMode;
}) {
  const { loading, selectedListingDetails } = useSelector((state: IRootState) => state.inventory);
  const { allShops } = useSelector((state: IRootState) => state.myShopsAPITable);

  const addSkeletonToComponents = (component: any) =>
    loading ? <Skeleton variant="rounded" sx={{ width: '100', height: '5rem' }} /> : component;

  // Get list of connected eBay marketplaces from allShops
  const connectedEbayMarketplaces = allShops
    ?.filter(
      (shop) =>
        shop.connectionStatus === ConnectionStatus.Connected &&
        shop.marketplace.includes(AutomationPlatform.EBAY)
    )
    .map((shop) => shop.marketplace);

  // Filter platform icons to show:
  // 1. All non-eBay platforms
  // 2. Major eBay marketplaces (US, AU, CA) always shown
  // 3. eBay marketplaces that exist in refs
  // 4. Connected eBay marketplaces
  // https://betalimited.atlassian.net/browse/PL-4975
  const filteredPlatformIcons = platformIcons.filter((platform) => {
    const refs = selectedListingDetails?.refs;

    // If it's not an eBay platform, always show it
    if (!platform.name.includes(AutomationPlatform.EBAY)) return true;

    // Always show major eBay marketplaces (US, AU, CA)
    if ([IPlatforms.EBAYUS, IPlatforms.EBAYAU, IPlatforms.EBAYCA].includes(platform.name))
      return true;

    // If this eBay marketplace exists in refs, show it
    if (refs && refs.hasOwnProperty(platform.name)) return true;

    // For other eBay marketplaces, only show if connected
    return connectedEbayMarketplaces?.includes(platform.name);
  });

  // Sort the platform icons by display name, prioritizing the ones that exist in the selected listing details.
  // Use alphabetical order inside both groups.
  const sortedPlatforms = filteredPlatformIcons.sort((a, b) => {
    const refs = selectedListingDetails?.refs;
    const aHasKey = refs && refs.hasOwnProperty(a.name);
    const bHasKey = refs && refs.hasOwnProperty(b.name);
    const aName = a.displayName.toLowerCase();
    const bName = b.displayName.toLowerCase();

    if (aHasKey && !bHasKey) {
      return -1;
    } else if (!aHasKey && bHasKey) {
      return 1;
    } else {
      return aName.localeCompare(bName);
    }
  });

  return (
    <Stack spacing={2}>
      {sortedPlatforms.map((platform) => (
        <Box key={platform.displayName}>
          {addSkeletonToComponents(
            <PlatfromLinksForm key={platform.displayName} platform={platform} mode={mode} />
          )}
        </Box>
      ))}
    </Stack>
  );
}
