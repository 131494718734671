import { useEffect } from 'react';

interface UseUnsavedChangesWarningProps {
  hasUnsavedChanges: boolean;
}

export const useUnsavedChangesWarning = ({ hasUnsavedChanges }: UseUnsavedChangesWarningProps) => {
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue = '';
        return '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [hasUnsavedChanges]);
}; 