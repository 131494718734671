// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: '/login',
  signup: '/sign-up',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
};

export const PATH_DASHBOARD = {
  root: '/home',
  groupsAndTags: '/groups-and-tags',
  user: {
    root: '/user',
  },
  tasks: '/tasks',
  orders: '/orders',
  subscription: {
    root: '/subscription',
    crosslist: '/subscription/crosslist',
    poshmarkAutomation: '/subscription/poshmark-automation',
    paymentSummary: '/subscription/payment-summary',
    ebayAutomation: '/subscription/ebay-automation',
  },
  inventory: {
    root: '/inventory',
    edit: '/inventory/:id',
    create: '/inventory/create',
  },
  myShops: '/my-shops',
  connect: {
    ebay: '/connect/ebay',
  },
};

export const PATH_AUTOMATIONS = {
  root: '/automations',
  poshmarkRoot: '/automations/poshmark',
  automations: '/automations/poshmark/settings',
  account: '/automations/poshmark/user',
  activity: '/automations/poshmark/activity',
  myCloset: '/automations/poshmark/my-closet',
  statistics: '/automations/poshmark/statistics',
  listings: '/automations/poshmark/my-listings',
  ebayRoot: '/automations/ebay',
  ebayAutomations: '/automations/ebay/settings',
  ebayActivity: '/automations/ebay/activity',
  ebayMyShops: '/automations/ebay/my-shops',
  ebayAccount: '/automations/ebay/user',
};
