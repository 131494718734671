import { GridFilterModel, GridLinkOperator, GridSortModel } from '@mui/x-data-grid-pro';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IOrdersRequest } from 'src/pages/dashboard/Orders/types/dataGridInterfaces';
import { IAPIMarketplaces } from './myShopsAPITableSlice';
import { getPlatformName } from 'src/pages/dashboard/Inventory/helpers/getFilterModelShaped';
import { IOrdersItemData } from 'src/pages/dashboard/Orders/types/dataGridInterfaces';
import { IUserActionQueueJobBase } from 'src/pages/dashboard/MyShops/types';
import {
  IOrderDetailRequest,
  IOrderTaskRecord,
} from 'src/pages/dashboard/Orders/types/dataGridInterfaces';
import { IOrderDetailResponse } from 'src/pages/dashboard/Orders/types/dataGridInterfaces';
import { IOrderRelatedItem } from 'src/pages/dashboard/Orders/types/dataGridInterfaces';
import { ActionSource, ProductType } from '@beta.limited/primelister';

export const officialPlatforms = [IAPIMarketplaces.EBAY];

export interface IOrdersState {
  dense: boolean;
  orders: IOrdersItemData[];
  ordersCount: number;
  page: number;
  rowsPerPage: number;
  loadingEffect: true;
  sortModel: GridSortModel;
  loading: boolean;
  error: string;
  searchValue: string;
  ordersFilterModel: GridFilterModel;
  isRefreshLoading: boolean;
  isOrderPageInFullscreenMode: boolean;
  orderDetails: {
    [key: string]: {
      loading: boolean;
      error: string | null;
      data: IOrderRelatedItem[];
    };
  };
  isManageSubscriptionsDialogOpen: boolean;
  manageSubscriptionDialogData: any | null;
  downloadExtensionDialogOpen: boolean;
}

const initialState: IOrdersState = {
  dense: false,
  orders: [],
  ordersCount: 0,
  page: 0,
  rowsPerPage: 25,
  loadingEffect: true,
  isOrderPageInFullscreenMode: false,
  sortModel: [{ field: 'added', sort: 'desc' }],
  loading: false,
  error: '',
  searchValue: '',
  isRefreshLoading: false,
  ordersFilterModel: {
    items: [],
    linkOperator: GridLinkOperator.And,
    quickFilterLogicOperator: GridLinkOperator.And,
    quickFilterValues: [],
  },
  orderDetails: {},
  isManageSubscriptionsDialogOpen: false,
  manageSubscriptionDialogData: null,
  downloadExtensionDialogOpen: false,
};

interface IDelistListing {
  id: string;
  image: string;
  title: string;
}

export interface IDelistOrderRequest {
  marketplace: string;
  shop: string;
  listings: IDelistListing[];
  orderId: string;
}

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setDense: (state) => {
      state.dense = !state.dense;
    },
    setIsInFullscreenMode: (state, action: PayloadAction<boolean>) => {
      state.isOrderPageInFullscreenMode = action.payload;
    },
    setDownloadExtensionDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.downloadExtensionDialogOpen = action.payload;
    },
    setOrders: (state, action) => {
      state.orders = action.payload;
    },
    setOrdersCount: (state, action) => {
      state.ordersCount = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setLoadingEffect: (state, action) => {
      state.loadingEffect = action.payload;
    },
    setSortModel: (state, action) => {
      state.sortModel = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setOrdersFilterModel: (state, action) => {
      state.ordersFilterModel = action.payload;
    },
    setIsRefreshLoading: (state, action: PayloadAction<boolean>) => {
      state.isRefreshLoading = action.payload;
    },
    //--
    ordersGetListRequest: (state, action: PayloadAction<IOrdersRequest>) => {
      state.loading = true;
    },
    ordersGetListSuccess: (state, action) => {
      state.loading = false;
      state.error = '';
      state.orders = action.payload;
    },
    ordersGetListFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    createUserActionQueueJobRequest: (state, action: PayloadAction<IUserActionQueueJobBase>) => {
      state.error = '';
    },
    createUserActionQueueJobSuccess: (state) => {
      state.error = '';
    },
    createUserActionQueueJobFail: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    //--
    resetInventoryState: (state) => initialState,
    orderDetailRequest: (state, action: PayloadAction<IOrderDetailRequest>) => {
      state.orderDetails[action.payload.id] = {
        ...state.orderDetails[action.payload.id],
        loading: true,
        error: null,
      };
    },
    orderDetailSuccess: (
      state,
      action: PayloadAction<{ id: string; data: IOrderRelatedItem[] }>
    ) => {
      state.orderDetails[action.payload.id] = {
        loading: false,
        error: null,
        data: action.payload.data,
      };
    },
    orderDetailFail: (state, action: PayloadAction<{ id: string; error: string }>) => {
      state.orderDetails[action.payload.id] = {
        ...state.orderDetails[action.payload.id],
        loading: false,
        error: action.payload.error,
      };
    },
    delistOrderRequest: (state, action: PayloadAction<IDelistOrderRequest>) => {
      state.loading = true;
      state.error = '';
    },
    delistOrderSuccess: (state) => {
      state.loading = false;
      state.error = '';
    },
    delistOrderFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    setIsManageSubscriptionsDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isManageSubscriptionsDialogOpen = action.payload;
    },
    setManageSubscriptionDialogData: (state, action: PayloadAction<any>) => {
      state.manageSubscriptionDialogData = action.payload;
    },
  },
});

export const {
  setDense,
  setIsInFullscreenMode,
  setOrders,
  setOrdersCount,
  setPage,
  setRowsPerPage,
  setLoadingEffect,
  setSortModel,
  setSearchValue,
  setOrdersFilterModel,
  setIsRefreshLoading,
  ordersGetListRequest,
  ordersGetListSuccess,
  ordersGetListFail,
  createUserActionQueueJobRequest,
  createUserActionQueueJobSuccess,
  createUserActionQueueJobFail,
  resetInventoryState,
  orderDetailRequest,
  orderDetailSuccess,
  orderDetailFail,
  delistOrderRequest,
  delistOrderSuccess,
  delistOrderFail,
  setIsManageSubscriptionsDialogOpen,
  setManageSubscriptionDialogData,
  setDownloadExtensionDialogOpen,
} = ordersSlice.actions;

export default ordersSlice.reducer;
