import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  MenuItem,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import QueueIcon from '@mui/icons-material/Queue';

import Iconify from 'src/components/iconify';
import MenuPopover from 'src/components/menu-popover';

import {
  inventoryGetListRequest,
  setBulkActionsListingList,
  setIsInFullscreenMode,
  setPage,
  setSearchValue,
  setSelectedCheckboxes,
} from 'src/store/dashboard/slices/inventorySlice';
import React, { ChangeEvent, startTransition, useEffect, useState } from 'react';
import { IRootState } from 'src/store';
import { GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import { getFilterModelShaped } from 'src/pages/dashboard/Inventory/helpers/getFilterModelShaped';
import { LoadingButton } from '@mui/lab';
import { useSearchParams } from 'react-router-dom';
import useResponsive from 'src/hooks/useResponsive';
import ImportButtonDropDown from '../../../components/ImportButtonDropDown';

export default function InventoryToolbar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    page,
    rowsPerPage,
    sortModel,
    inventoryFilterModel,
    searchValue,
    isInFullscreenMode,
    isRefreshLoading,
  } = useSelector((state: IRootState) => state.inventory);

  const [openDropdown, setOpenDropdown] = useState<HTMLElement | null>(null);

  const onChangeTimer = React.useRef<any>();
  const isMobile = useResponsive('down', 900);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(setSearchValue(e.target.value));

    clearTimeout(onChangeTimer.current);

    onChangeTimer.current = setTimeout(() => {
      const orderBy = sortModel.length > 0 ? sortModel[0].field : 'added';
      const order = sortModel.length > 0 ? sortModel[0].sort : 'desc';
      if (e.target.value.length !== 1) {
        dispatch(setBulkActionsListingList([]));
        dispatch(setSelectedCheckboxes([]));
        dispatch(
          inventoryGetListRequest({
            page: page,
            rowsPerPage: Number(rowsPerPage),
            orderBy,
            order,
            filter: getFilterModelShaped(inventoryFilterModel),
            searchValue: e.target.value.trim(),
          })
        );
      }

      if (e.target.value !== '') {
        searchParams.set('search', e.target.value.trim());
        setSearchParams(searchParams);
      } else {
        searchParams.delete('search');
        setSearchParams(searchParams);
      }
    }, 1000);

    if (page !== 0) {
      dispatch(setPage(0));
    }
  };

  const handleSearchClear = () => {
    const orderBy = sortModel.length > 0 ? sortModel[0].field : 'added';
    const order = sortModel.length > 0 ? sortModel[0].sort : 'desc';
    dispatch(setBulkActionsListingList([]));
    dispatch(setSelectedCheckboxes([]));
    dispatch(setPage(0));
    dispatch(setSearchValue(''));
    searchParams.delete('search');
    setSearchParams(searchParams);
    dispatch(
      inventoryGetListRequest({
        page: page,
        rowsPerPage: Number(rowsPerPage),
        orderBy,
        order,
        filter: getFilterModelShaped(inventoryFilterModel),
        searchValue: '',
      })
    );
  };

  const handleFullscreen = () => {
    dispatch(setIsInFullscreenMode(!isInFullscreenMode));
  };

  const handleRefresh = () => {
    const orderBy = sortModel.length > 0 ? sortModel[0].field : 'added';
    const order = sortModel.length > 0 ? sortModel[0].sort : 'desc';
    dispatch(
      inventoryGetListRequest({
        page,
        rowsPerPage,
        orderBy,
        order,
        filter: getFilterModelShaped(inventoryFilterModel),
        searchValue,
        refresh: true,
      })
    );
  };

  const handleDropdownOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpenDropdown(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setOpenDropdown(null);
  };

  const handleCreateNew = () => {
    handleDropdownClose();
    navigate('/inventory/create');
  };

  const isClearButtonVisible = searchValue.length > 0;

  return (
    <Stack bgcolor="#f3f6f8" justifyContent="center" alignItems="center">
      <Stack
        spacing={1}
        direction={{ xs: 'column', md: 'row' }}
        alignItems={{ md: 'center' }}
        sx={{ width: 1, p: 1, pr: 2 }}
      >
        <Stack
          spacing={1}
          direction={{ xs: 'column', md: 'row' }}
          alignItems={{ md: 'center' }}
          sx={{ width: 1, p: 1, paddingLeft: '0' }}
        >
          <GridToolbarFilterButton
            sx={{ p: 1, color: '#000000', marginRight: '4px' }}
            nonce={undefined}
            onResize={undefined}
            onResizeCapture={undefined}
          />
          {!isMobile && (
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                height: '24px !important',
                marginTop: '0 !important',
                width: '1px !important',
                marginRight: '12px !important',
                backgroundColor: '#C4CDD5 !important',
                alignSelf: 'center !important',
              }}
            />
          )}
          <TextField
            size="small"
            value={searchValue}
            onChange={handleChange}
            placeholder="Search product name, SKU or internal note..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
            }}
            sx={{
              maxWidth: !isMobile ? '643.9px' : '100%',
              width: '100%',
              '& .MuiOutlinedInput-root': {
                bgcolor: 'white',
              },
            }}
          />

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
            {isClearButtonVisible && (
              <Button
                variant="soft"
                color="error"
                onClick={handleSearchClear}
                startIcon={<Iconify icon="eva:trash-2-outline" />}
              >
                Clear
              </Button>
            )}
          </Stack>
        </Stack>

        <Button
          variant="text"
          className="fullscreenModeButton"
          sx={{
            px: 2,
            color: '#212B36',
            fontSize: '13px',
            width: isMobile ? '100%' : isInFullscreenMode ? '147px' : '114px',
            minWidth: isMobile ? '100%' : isInFullscreenMode ? '147px' : '114px',
            marginLeft: '0 !important',
            whiteSpace: 'nowrap',
          }}
          onClick={handleFullscreen}
          startIcon={
            !isInFullscreenMode ? (
              <Iconify
                icon="mingcute:fullscreen-fill"
                width={24}
                height={24}
                sx={{
                  marginRight: '0 !important',
                }}
              />
            ) : (
              <Iconify
                icon="mingcute:fullscreen-exit-fill"
                width={24}
                height={24}
                sx={{
                  marginRight: '0 !important',
                }}
              />
            )
          }
        >
          <Typography
            sx={{
              fontSize: '13px',
              fontWeight: 700,
            }}
          >
            {isInFullscreenMode ? 'Exit Fullscreen' : 'Fullscreen'}
          </Typography>
        </Button>

        <LoadingButton
          loading={isRefreshLoading}
          variant="text"
          color="primary"
          onClick={handleRefresh}
          sx={{
            px: 2,
            color: '#212B36',
            width: isMobile ? '100%' : '97px',
            minWidth: isMobile ? '100%' : '97px',
            marginLeft: '0 !important',
          }}
          startIcon={
            <Iconify icon="raphael:refresh" width={24} height={24} marginRight={'0!important'} />
          }
        >
          <Typography
            sx={{
              fontSize: '13px',
              fontWeight: 700,
            }}
          >
            Refresh
          </Typography>
        </LoadingButton>

        {!isMobile && (
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              height: '24px !important',
              marginTop: '0 !important',
              width: '1px !important',
              marginRight: '12px !important',
              marginLeft: '10px !important',
              backgroundColor: '#C4CDD5 !important',
              alignSelf: 'center !important',
            }}
          />
        )}

        <Box sx={{ position: 'relative' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDropdownOpen}
            startIcon={<QueueIcon />}
            sx={{
              width: !isMobile ? '157.2px' : '100%',
              minWidth: !isMobile ? '157.2px' : '100%',
              padding: '5.75px 15.643px 6.25px 16px',
              height: '36px',
            }}
          >
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 700,
                color: 'white',
              }}
            >
              New Listing
            </Typography>
          </Button>

          <MenuPopover
            open={openDropdown}
            onClose={handleDropdownClose}
            arrow={'top-center'}
            sx={{ minWidth: 140, mt: 0.5 }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <ImportButtonDropDown isInNewListingDropdown onParentClose={handleDropdownClose} />
            <MenuItem onClick={handleCreateNew}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography sx={{ fontSize: '14px', fontWeight: 400, color: 'text.primary' }}>
                  Create New
                </Typography>
              </Stack>
            </MenuItem>
          </MenuPopover>
        </Box>
      </Stack>
    </Stack>
  );
}
