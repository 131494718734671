import { GridFilterModel, GridLinkOperator, GridSortModel } from '@mui/x-data-grid-pro';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IBulkActionsSelectedListings,
  IInventoryGroupActionPayload,
  IInventoryTagActionPayload,
  IInventoryDeleteRequestPayload,
  IInventoryItemData,
  IInventoryMergeListingsRequestPayload,
  IInventoryPopoverData,
  IInventoryRequest,
  IInventoryUpdateStatusRequestPayload,
  IListingSources,
  ISelectedListing,
  DataGridGroupsColumnInterface,
  DataGridTagsColumnInterface,
  IInventoryTag,
} from 'src/pages/dashboard/Inventory/types/dataGridInterfaces';
import {
  CreateNewListingInput,
  ListingStatus,
} from 'src/pages/dashboard/Inventory/types/newListingInterfaces';
import { IAPIMarketplaces } from './myShopsAPITableSlice';
import { getPlatformName } from 'src/pages/dashboard/Inventory/helpers/getFilterModelShaped';
import { InventoryQuickEditPopoverEnum } from 'src/pages/dashboard/Inventory/mainComponents/InventoryPage/others/InventoryQuickEditPopover';
import { IUserActionQueueJobBase } from 'src/pages/dashboard/MyShops/types';
import { IGroupData, ITagData } from './groupsAndTagsSlice';
import { NavigateFunction } from 'react-router';

export const officialPlatforms = [IAPIMarketplaces.EBAY];

export enum QuickActionsTagPopoverModeEnum {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

export enum QuickActionsGroupPopoverModeEnum {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

export interface IInventoryState {
  dense: boolean;
  deletePopup: boolean;
  deletePopupData: string;
  deleteType: number;
  openPopoverMenu: HTMLElement | null;
  openBulkPopoverMenu: HTMLElement | null;
  popoverData: IInventoryPopoverData | null;
  inventory: IInventoryItemData[];
  inventoryCount: number;
  page: number;
  rowsPerPage: number;
  loadingEffect: true;
  selectedCheckboxes: any[];
  sortModel: GridSortModel;
  loading: boolean;
  error: string;
  searchValue: string;
  openCrosslistDialog: boolean;
  openBulkCrosslistDialog: boolean;
  openDownloadExtensionDialog: boolean;
  openAPIConnectionRequiredDialog: boolean;
  inventoryFilterModel: GridFilterModel;
  openInventoryAddGroupDialog: boolean;
  selectedGroupToAdd: string;
  openInventoryAddTagDialog: boolean;
  selectedTagsToAdd: string[];
  openInventoryUpdateStatusDialog: boolean;
  selectedStatusToUpdate: ListingStatus | '';
  openInventoryMergeListingsDialog: boolean;
  openInventoryMergeListingsFailDialog: boolean;
  isInventoryLinkDeleteDialogOpen: boolean;
  linkDeleteDialogData: {
    name: string;
    displayName: string;
  };
  isLastLinkRemoveErrorDialogOpen: boolean;
  openExtensionCommonDialog: false;
  extensionActionType: number;
  selectedListingDetails: ISelectedListing;
  bulkActionsListingList: IBulkActionsSelectedListings[];
  hoveredListingId: string;
  crosslistSourceLoginErrorPlatforms: IListingSources[];
  crosslistSourceLoginErrorPlatformQuickMenu: IListingSources[];
  isInFullscreenMode: boolean;
  isRefreshLoading: boolean;
  isManageSubscriptionsDialogOpen: boolean;
  manageSubscriptionDialogData: null | {
    code: string;
    message: string;
    redirectUrl: string;
    shortMessage: string;
  };
  isRemoveGroupsDialogOpen: boolean;
  isRemoveTagsDialogOpen: boolean;
  isInventoryQuickEditPopoverOpen: boolean;
  selectedQuickEditItemType: InventoryQuickEditPopoverEnum | null;
  currentSelectedGroup: DataGridGroupsColumnInterface['groups'] | null;
  currentSelectedStatus: string | null;
  quickEditSelectedListing: any | null;
  quickEditAnchorPosition: { top: number | null; left: number | null };
  currentSelectedTags: DataGridTagsColumnInterface['tags'] | null;
  isInventoryQuickEditStatusPopoverOpen: boolean;
  isTagEditPopoverOpen: boolean;
  tagEditPopoverAnchorEl: any;
  tagEditPopoverAnchorPosition: { top: number | null; left: number | null };
  selectedTagEditItem: ITagData | null;
  quickActionsTagPopoverMode: QuickActionsTagPopoverModeEnum | null;
  quickEditSelectedTags: ITagData[] | IInventoryTag[];
  isGroupEditPopoverOpen: boolean;
  groupEditPopoverAnchorEl: any;
  selectedGroupEditItem: IGroupData | null;
  quickActionsGroupPopoverMode: QuickActionsGroupPopoverModeEnum | null;
  isEditFormSaveChangesDisabled: boolean;
  isGroupAutocompleteOpen: boolean;
  isTagsAutocompleteOpen: boolean;
  isExtensionUpdateDialogOpen: boolean;
  isImportingImages: boolean;
  createModeLinks: {
    [key: string]: {
      listingLink: string;
      id: string;
      added: number;
      updated: number;
      closetName?: string;
      isValid: boolean;
    };
  };
  createListingModeImages: {
    id: string;
    url: string;
    name: string;
    orderIndex: number;
    file?: File;
    isSelected?: boolean;
    type: 'image' | 'video';
    thumbnailUrl?: string;
  }[];
  createListingLoading: boolean;
  createListingError: string | null;
  importImagesError: string | null;
  isUpdatingListingContents: boolean;
}

const initialState: IInventoryState = {
  dense: false,
  deletePopup: false,
  deletePopupData: '',
  deleteType: 0, // initial value is 0, 1 is for bulk delete, 2 is for single delete
  openPopoverMenu: null,
  openBulkPopoverMenu: null,
  popoverData: null,
  inventory: [],
  inventoryCount: 0,
  page: 0,
  rowsPerPage: 25,
  loadingEffect: true,
  selectedCheckboxes: [],
  sortModel: [{ field: 'added', sort: 'desc' }],
  loading: false,
  error: '',
  searchValue: '',
  inventoryFilterModel: {
    items: [],
    linkOperator: GridLinkOperator.And,
    quickFilterLogicOperator: GridLinkOperator.And,
    quickFilterValues: [],
  },
  openCrosslistDialog: false,
  openBulkCrosslistDialog: false,
  openDownloadExtensionDialog: false,
  openInventoryAddGroupDialog: false,
  selectedGroupToAdd: '',
  openInventoryAddTagDialog: false,
  selectedTagsToAdd: [],
  openInventoryUpdateStatusDialog: false,
  selectedStatusToUpdate: '',
  openInventoryMergeListingsDialog: false,
  openInventoryMergeListingsFailDialog: false,
  isInventoryLinkDeleteDialogOpen: false,
  linkDeleteDialogData: {
    name: '',
    displayName: '',
  },
  isLastLinkRemoveErrorDialogOpen: false,
  openExtensionCommonDialog: false,
  extensionActionType: 0,
  selectedListingDetails: {
    groups: [
      { name: '', _id: '' },
      { name: '', _id: '' },
    ],
    image: '',
    status: '',
    tags: [{ name: '', _id: '', color: '' }],
    listingTags: [{ name: '', id: '', color: '' }],
    inertialNotes: '',
  },
  bulkActionsListingList: [],
  hoveredListingId: '',
  crosslistSourceLoginErrorPlatforms: [],
  crosslistSourceLoginErrorPlatformQuickMenu: [],
  isInFullscreenMode: false,
  isRefreshLoading: false,
  openAPIConnectionRequiredDialog: false,
  isManageSubscriptionsDialogOpen: false,
  manageSubscriptionDialogData: null,
  isRemoveGroupsDialogOpen: false,
  isRemoveTagsDialogOpen: false,
  isInventoryQuickEditPopoverOpen: false,
  selectedQuickEditItemType: null,
  currentSelectedGroup: null,
  quickEditSelectedListing: null,
  quickEditAnchorPosition: { top: null, left: null },
  currentSelectedTags: null,
  isInventoryQuickEditStatusPopoverOpen: false,
  currentSelectedStatus: null,
  isTagEditPopoverOpen: false,
  tagEditPopoverAnchorEl: null,
  selectedTagEditItem: null,
  tagEditPopoverAnchorPosition: { top: null, left: null },
  quickActionsTagPopoverMode: null,
  quickEditSelectedTags: [],
  isGroupEditPopoverOpen: false,
  groupEditPopoverAnchorEl: null,
  selectedGroupEditItem: null,
  quickActionsGroupPopoverMode: null,
  isEditFormSaveChangesDisabled: true,
  isGroupAutocompleteOpen: false,
  isTagsAutocompleteOpen: false,
  isExtensionUpdateDialogOpen: false,
  isImportingImages: false,
  createModeLinks: {},
  createListingModeImages: [],
  createListingLoading: false,
  createListingError: null,
  importImagesError: null,
  isUpdatingListingContents: false,
};

const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    setIsExtensionUpdateDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isExtensionUpdateDialogOpen = action.payload;
    },
    setCurrentSelectedTags: (state, action: PayloadAction<DataGridTagsColumnInterface['tags']>) => {
      state.currentSelectedTags = action.payload;
    },
    setCurrentSelectedStatus: (state, action: PayloadAction<string>) => {
      state.currentSelectedStatus = action.payload;
    },
    setQuickEditAnchorPosition: (state, action: PayloadAction<{ top: number; left: number }>) => {
      state.quickEditAnchorPosition = action.payload;
    },
    setIsInventoryQuickEditPopoverOpen: (state, action: PayloadAction<boolean>) => {
      state.isInventoryQuickEditPopoverOpen = action.payload;
    },
    setIsInventoryQuickEditStatusPopoverOpen: (state, action: PayloadAction<boolean>) => {
      state.isInventoryQuickEditStatusPopoverOpen = action.payload;
    },
    setSelectedQuickEditItemType: (state, action: PayloadAction<InventoryQuickEditPopoverEnum>) => {
      state.selectedQuickEditItemType = action.payload;
    },
    setCurrentSelectedGroup: (
      state,
      action: PayloadAction<DataGridGroupsColumnInterface['groups']>
    ) => {
      state.currentSelectedGroup = action.payload;
    },
    setQuickEditSelectedListing: (state, action: PayloadAction<any>) => {
      state.quickEditSelectedListing = action.payload;
    },
    setIsEditFormSaveChangesDisabled: (state, action: PayloadAction<boolean>) => {
      state.isEditFormSaveChangesDisabled = action.payload;
    },
    setDense: (state) => {
      state.dense = !state.dense;
    },
    setIsInFullscreenMode: (state, action: PayloadAction<boolean>) => {
      state.isInFullscreenMode = action.payload;
    },
    setDeletePopup: (state, action) => {
      state.deletePopup = action.payload;
    },
    setDeletePopupData: (state, action) => {
      state.deletePopupData = action.payload;
    },
    setDeleteType: (state, action) => {
      // initial value is 0, 1 is for bulk delete, 2 is for single delete
      state.deleteType = action.payload;
    },
    setOpenPopoverMenu: (state, action) => {
      state.openPopoverMenu = action.payload;
    },
    setOpenBulkPopoverMenu: (state, action) => {
      state.openBulkPopoverMenu = action.payload;
    },
    setPopoverData: (state, action) => {
      state.popoverData = action.payload;
    },
    setInventory: (state, action) => {
      state.inventory = action.payload;
    },
    setInventoryCount: (state, action) => {
      state.inventoryCount = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setLoadingEffect: (state, action) => {
      state.loadingEffect = action.payload;
    },
    setSelectedCheckboxes: (state, action) => {
      state.selectedCheckboxes = action.payload;
    },
    setSortModel: (state, action) => {
      state.sortModel = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setInventoryFilterModel: (state, action) => {
      state.inventoryFilterModel = action.payload;
    },
    setIsRemoveGroupsDialogOpen: (state, action) => {
      state.isRemoveGroupsDialogOpen = action.payload;
    },
    setIsRemoveTagsDialogOpen: (state, action) => {
      state.isRemoveTagsDialogOpen = action.payload;
    },
    setOpenCrosslistDialog: (state, action) => {
      state.openCrosslistDialog = action.payload;
    },
    setOpenBulkCrosslistDialog: (state, action) => {
      state.openBulkCrosslistDialog = action.payload;
    },
    setOpenDownloadExtensionDialog: (state, action) => {
      state.openDownloadExtensionDialog = action.payload;
    },
    setOpenAPIConnectionRequiredDialog: (state, action) => {
      state.openAPIConnectionRequiredDialog = action.payload;
    },
    setOpenInventoryAddGroupDialog: (state, action) => {
      state.openInventoryAddGroupDialog = action.payload;
    },
    setSelectedGroupToAdd: (state, action) => {
      state.selectedGroupToAdd = action.payload;
    },
    setOpenInventoryAddTagDialog: (state, action) => {
      state.openInventoryAddTagDialog = action.payload;
    },
    setSelectedTagsToAdd: (state, action) => {
      state.selectedTagsToAdd = action.payload;
    },
    removeSelectedTagsToAdd: (state, action) => {
      state.selectedTagsToAdd = action.payload;
    },
    setOpenInventoryUpdateStatusDialog: (state, action) => {
      state.openInventoryUpdateStatusDialog = action.payload;
    },
    setSelectedStatusToUpdate: (state, action) => {
      state.selectedStatusToUpdate = action.payload;
    },
    setOpenInventoryMergeListingsDialog: (state, action) => {
      state.openInventoryMergeListingsDialog = action.payload;
    },
    setOpenInventoryMergeListingsFailDialog: (state, action) => {
      state.openInventoryMergeListingsFailDialog = action.payload;
    },
    setIsInventoryLinkDeleteDialogOpen: (state, action) => {
      state.isInventoryLinkDeleteDialogOpen = action.payload;
    },
    setLinkDeleteDialogData: (
      state,
      action: PayloadAction<{ name: string; displayName: string }>
    ) => {
      state.linkDeleteDialogData.displayName = action.payload.displayName;
      state.linkDeleteDialogData.name = action.payload.name;
    },
    setIsLastLinkRemoveErrorDialogOpen: (state, action) => {
      state.isLastLinkRemoveErrorDialogOpen = action.payload;
    },
    setOpenExtensionCommonDialog: (state, action) => {
      state.openExtensionCommonDialog = action.payload;
    },
    setExtensionActionType: (state, action) => {
      state.extensionActionType = action.payload;
    },
    setSelectedListingDetails: (state, action) => {
      state.selectedListingDetails = action.payload;
    },
    setBulkActionsListingList: (state, action) => {
      state.bulkActionsListingList = action.payload;
    },
    setCrosslistSourceLoginErrorPlatformsBulk: (state, action) => {
      // exclude official platforms
      state.crosslistSourceLoginErrorPlatforms = action.payload.filter(
        (platform: IListingSources) =>
          !officialPlatforms.includes(
            getPlatformName(platform.platformName) as unknown as IAPIMarketplaces
          )
      );
    },
    setCrosslistSourceLoginErrorPlatformQuickMenu: (state, action) => {
      state.crosslistSourceLoginErrorPlatformQuickMenu = action.payload;
    },
    setHoveredListingId: (state, action) => {
      state.hoveredListingId = action.payload;
    },
    setIsRefreshLoading: (state, action: PayloadAction<boolean>) => {
      state.isRefreshLoading = action.payload;
    },
    //--
    inventoryGetListRequest: (state, action: PayloadAction<IInventoryRequest>) => {
      state.loading = true;
    },
    inventoryGetListSuccess: (state, action) => {
      state.loading = false;
      state.error = '';
      state.inventory = action.payload;
    },
    inventoryGetListFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    //--
    inventoryDeleteRequest: (state, action: PayloadAction<IInventoryDeleteRequestPayload>) => {
      state.loading = true;
    },
    inventoryDeleteSuccess: (state) => {
      state.loading = false;
      state.error = '';
    },
    inventoryDeleteFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    //--
    inventoryAddToGroupRequest: (state, action: PayloadAction<IInventoryGroupActionPayload>) => {
      state.loading = true;
      state.error = '';
    },
    inventoryAddToGroupSuccess: (state) => {
      state.loading = false;
      state.error = '';
    },
    inventoryAddToGroupFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    //--
    inventoryRemoveGroupsRequest: (state, action: PayloadAction<IInventoryGroupActionPayload>) => {
      state.loading = true;
      state.error = '';
    },
    inventoryRemoveGroupsSuccess: (state) => {
      state.loading = false;
      state.error = '';
    },
    inventoryRemoveGroupsFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    //--
    inventoryAddToTagRequest: (state, action: PayloadAction<IInventoryTagActionPayload>) => {
      state.loading = true;
      state.error = '';
    },
    inventoryAddToTagSuccess: (state) => {
      state.loading = false;
      state.error = '';
    },
    inventoryAddToTagFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    inventoryUpdateTagsRequest: (state, action: PayloadAction<IInventoryTagActionPayload>) => {
      state.loading = true;
      state.error = '';
    },
    inventoryUpdateTagsSuccess: (state) => {
      state.loading = false;
      state.error = '';
    },
    inventoryUpdateTagsFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    //--
    inventoryRemoveTagsRequest: (state, action: PayloadAction<IInventoryTagActionPayload>) => {
      state.loading = true;
      state.error = '';
    },
    inventoryRemoveTagsSuccess: (state) => {
      state.loading = false;
      state.error = '';
    },
    inventoryRemoveTagsFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    //--
    inventoryUpdateStatusRequest: (
      state,
      action: PayloadAction<IInventoryUpdateStatusRequestPayload>
    ) => {
      state.loading = true;
      state.error = '';
    },
    inventoryUpdateStatusSuccess: (state) => {
      state.loading = false;
      state.error = '';
    },
    inventoryUpdateStatusFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    //--
    inventoryMergeListingsRequest: (
      state,
      action: PayloadAction<IInventoryMergeListingsRequestPayload>
    ) => {
      state.loading = true;
      state.error = '';
    },
    inventoryMergeListingsSuccess: (state) => {
      state.loading = false;
      state.error = '';
    },
    inventoryMergeListingsFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    //--
    inventoryGetListingDetailsToEditRequest: (state, action: PayloadAction<string>) => {
      state.loading = true;
      state.error = '';
    },
    inventoryGetListingDetailsToEditSuccess: (state) => {
      state.loading = false;
      state.error = '';
    },
    inventoryGetListingDetailsToEditFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    //--
    inventoryEditSelectedItemRequest: (state, action) => {
      state.loading = true;
      state.error = '';
    },
    inventoryEditSelectedItemSuccess: (state) => {
      state.loading = false;
      state.error = '';
    },
    inventoryEditSelectedItemFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setIsManageSubscriptionsDialogOpen: (state, action) => {
      state.isManageSubscriptionsDialogOpen = action.payload;
    },
    setManageSubscriptionDialogData: (state, action) => {
      state.manageSubscriptionDialogData = action.payload;
    },

    createUserActionQueueJobRequest: (state, action: PayloadAction<IUserActionQueueJobBase>) => {
      state.error = '';
    },
    createUserActionQueueJobSuccess: (state) => {
      state.error = '';
    },
    createUserActionQueueJobFail: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setQuickActionsTagPopoverMode: (
      state,
      action: PayloadAction<QuickActionsTagPopoverModeEnum | null>
    ) => {
      state.quickActionsTagPopoverMode = action.payload;
    },
    setIsTagEditPopoverOpen: (state, action: PayloadAction<boolean>) => {
      state.isTagEditPopoverOpen = action.payload;
    },
    setTagEditPopoverAnchorEl: (state, action: PayloadAction<any>) => {
      state.tagEditPopoverAnchorEl = action.payload;
    },
    setSelectedTagEditItem: (state, action: PayloadAction<ITagData | null>) => {
      state.selectedTagEditItem = action.payload;
    },
    setTagEditPopoverAnchorPosition: (
      state,
      action: PayloadAction<{ top: number | null; left: number | null }>
    ) => {
      state.tagEditPopoverAnchorPosition = action.payload;
    },
    setQuickEditSelectedTags: (state, action: PayloadAction<ITagData[] | IInventoryTag[]>) => {
      state.quickEditSelectedTags = action.payload;
    },
    setIsGroupEditPopoverOpen: (state, action: PayloadAction<boolean>) => {
      state.isGroupEditPopoverOpen = action.payload;
    },
    setGroupEditPopoverAnchorEl: (state, action: PayloadAction<any>) => {
      state.groupEditPopoverAnchorEl = action.payload;
    },
    setSelectedGroupEditItem: (state, action: PayloadAction<IGroupData | null>) => {
      state.selectedGroupEditItem = action.payload;
    },
    setQuickActionsGroupPopoverMode: (
      state,
      action: PayloadAction<QuickActionsGroupPopoverModeEnum | null>
    ) => {
      state.quickActionsGroupPopoverMode = action.payload;
    },
    setIsGroupAutocompleteOpen: (state, action: PayloadAction<boolean>) => {
      state.isGroupAutocompleteOpen = action.payload;
    },
    setIsTagsAutocompleteOpen: (state, action: PayloadAction<boolean>) => {
      state.isTagsAutocompleteOpen = action.payload;
    },
    //--
    resetInventoryState: (state) => initialState,

    inventoryImportImagesRequest: (
      state,
      action: PayloadAction<{
        itemId: string;
        listingId: string;
        platform: string;
      }>
    ) => {
      state.isImportingImages = true;
      state.importImagesError = null;
    },
    inventoryImportImagesSuccess: (
      state,
      action: PayloadAction<{
        images: {
          id?: string;
          url: string;
          name: string;
          orderIndex: number;
          file?: File;
          isSelected?: boolean;
          type?: 'image' | 'video';
          thumbnailUrl?: string;
        }[];
        image: string;
      }>
    ) => {
      state.isImportingImages = false;
      state.importImagesError = null;
      state.selectedListingDetails.images = action.payload.images;
      state.selectedListingDetails.image = action.payload.image;
    },
    inventoryImportImagesFail: (state, action: PayloadAction<string>) => {
      state.isImportingImages = false;
      state.importImagesError = action.payload;
    },
    setCreateModeLink: (
      state,
      action: PayloadAction<{
        platform: string;
        link: {
          listingLink: string;
          id: string;
          added: number;
          updated: number;
          closetName?: string;
          isValid: boolean;
        };
      }>
    ) => {
      state.createModeLinks[action.payload.platform] = action.payload.link;
    },
    removeCreateModeLink: (state, action: PayloadAction<string>) => {
      delete state.createModeLinks[action.payload];
    },
    clearCreateModeLinks: (state) => {
      state.createModeLinks = {};
    },
    setCreateListingModeImages: (
      state,
      action: PayloadAction<IInventoryState['createListingModeImages']>
    ) => {
      state.createListingModeImages = action.payload;
    },
    addCreateListingModeImages: (
      state,
      action: PayloadAction<IInventoryState['createListingModeImages']>
    ) => {
      state.createListingModeImages = [...state.createListingModeImages, ...action.payload];
    },
    removeCreateListingModeImage: (state, action: PayloadAction<string>) => {
      state.createListingModeImages = state.createListingModeImages.filter(
        (image) => image.id !== action.payload
      );
    },
    reorderCreateListingModeImages: (
      state,
      action: PayloadAction<IInventoryState['createListingModeImages']>
    ) => {
      state.createListingModeImages = action.payload;
    },
    clearCreateListingModeImages: (state) => {
      state.createListingModeImages = [];
    },
    // Create Listing Actions
    createListingRequest: (
      state,
      action: PayloadAction<{
        data: CreateNewListingInput;
        contents: File[];
        navigate: NavigateFunction;
      }>
    ) => {
      state.createListingLoading = true;
      state.createListingError = null;
    },
    createListingSuccess: (state) => {
      state.createListingLoading = false;
      state.createListingError = null;
    },
    createListingFail: (state, action: PayloadAction<string>) => {
      state.createListingLoading = false;
      state.createListingError = action.payload;
    },
    //--
    inventoryUpdateListingContentsRequest: (
      state,
      action: PayloadAction<{ id: string; contents: FormData }>
    ) => {
      state.isUpdatingListingContents = true;
      state.error = '';
    },
    inventoryUpdateListingContentsSuccess: (state) => {
      state.isUpdatingListingContents = false;
      state.error = '';
    },
    inventoryUpdateListingContentsFail: (state, action) => {
      state.isUpdatingListingContents = false;
      state.error = action.payload;
    },
  },
});

export const {
  setIsGroupAutocompleteOpen,
  setIsTagsAutocompleteOpen,
  setQuickEditSelectedTags,
  setIsTagEditPopoverOpen,
  setIsGroupEditPopoverOpen,
  setGroupEditPopoverAnchorEl,
  setSelectedGroupEditItem,
  setIsEditFormSaveChangesDisabled,
  setQuickActionsGroupPopoverMode,
  setQuickActionsTagPopoverMode,
  setTagEditPopoverAnchorPosition,
  setTagEditPopoverAnchorEl,
  setSelectedTagEditItem,
  setIsExtensionUpdateDialogOpen,
  createUserActionQueueJobFail,
  createUserActionQueueJobRequest,
  createUserActionQueueJobSuccess,
  setQuickEditAnchorPosition,
  setCurrentSelectedTags,
  setIsManageSubscriptionsDialogOpen,
  setManageSubscriptionDialogData,
  setQuickEditSelectedListing,
  setCurrentSelectedGroup,
  resetInventoryState,
  setIsRemoveGroupsDialogOpen,
  setDense,
  setDeletePopup,
  setDeletePopupData,
  setDeleteType,
  setOpenPopoverMenu,
  setOpenBulkPopoverMenu,
  setPopoverData,
  setInventory,
  setInventoryCount,
  setPage,
  setRowsPerPage,
  setLoadingEffect,
  setSelectedCheckboxes,
  setSortModel,
  setSearchValue,
  setInventoryFilterModel,
  setOpenCrosslistDialog,
  setOpenBulkCrosslistDialog,
  setOpenDownloadExtensionDialog,
  setOpenInventoryAddGroupDialog,
  setSelectedGroupToAdd,
  setOpenInventoryAddTagDialog,
  setIsRemoveTagsDialogOpen,
  setSelectedTagsToAdd,
  removeSelectedTagsToAdd,
  setOpenAPIConnectionRequiredDialog,
  setOpenInventoryUpdateStatusDialog,
  setSelectedStatusToUpdate,
  setOpenInventoryMergeListingsDialog,
  setOpenInventoryMergeListingsFailDialog,
  setSelectedListingDetails,
  setIsInventoryLinkDeleteDialogOpen,
  setLinkDeleteDialogData,
  setIsLastLinkRemoveErrorDialogOpen,
  setOpenExtensionCommonDialog,
  setExtensionActionType,
  setBulkActionsListingList,
  setCrosslistSourceLoginErrorPlatformsBulk,
  setCrosslistSourceLoginErrorPlatformQuickMenu,
  setHoveredListingId,
  setIsInFullscreenMode,
  setIsRefreshLoading,
  //--
  inventoryGetListRequest,
  inventoryGetListSuccess,
  inventoryGetListFail,
  //--
  inventoryDeleteRequest,
  inventoryDeleteSuccess,
  inventoryDeleteFail,
  //--
  inventoryAddToGroupRequest,
  inventoryAddToGroupSuccess,
  inventoryAddToGroupFail,
  //--
  inventoryRemoveGroupsRequest,
  inventoryRemoveGroupsSuccess,
  inventoryRemoveGroupsFail,
  //--
  inventoryAddToTagRequest,
  inventoryAddToTagSuccess,
  inventoryAddToTagFail,
  //--
  inventoryUpdateTagsFail,
  inventoryUpdateTagsRequest,
  inventoryUpdateTagsSuccess,

  //--
  inventoryRemoveTagsRequest,
  inventoryRemoveTagsSuccess,
  inventoryRemoveTagsFail,
  //--
  inventoryUpdateStatusRequest,
  inventoryUpdateStatusSuccess,
  inventoryUpdateStatusFail,
  //--
  inventoryMergeListingsRequest,
  inventoryMergeListingsSuccess,
  inventoryMergeListingsFail,
  //--
  inventoryGetListingDetailsToEditRequest,
  inventoryGetListingDetailsToEditSuccess,
  inventoryGetListingDetailsToEditFail,
  //--
  inventoryEditSelectedItemRequest,
  inventoryEditSelectedItemSuccess,
  inventoryEditSelectedItemFail,

  setIsInventoryQuickEditPopoverOpen,
  setIsInventoryQuickEditStatusPopoverOpen,
  setSelectedQuickEditItemType,
  setCurrentSelectedStatus,

  inventoryImportImagesRequest,
  inventoryImportImagesSuccess,
  inventoryImportImagesFail,

  setCreateModeLink,
  removeCreateModeLink,
  clearCreateModeLinks,

  setCreateListingModeImages,
  addCreateListingModeImages,
  removeCreateListingModeImage,
  reorderCreateListingModeImages,
  clearCreateListingModeImages,

  createListingRequest,
  createListingSuccess,
  createListingFail,

  inventoryUpdateListingContentsRequest,
  inventoryUpdateListingContentsSuccess,
  inventoryUpdateListingContentsFail,
} = inventorySlice.actions;

export default inventorySlice.reducer;
