import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, delay, put, takeLatest, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { IRootState } from 'src/store';

import axiosInstance from 'src/utils/axios';
import { crosslistEndpoints } from 'src/endpoints';

import {
  setInventoryCount,
  inventoryGetListFail,
  inventoryGetListRequest,
  inventoryGetListSuccess,
  setLoadingEffect,
  inventoryDeleteRequest,
  inventoryDeleteFail,
  inventoryDeleteSuccess,
  setDeletePopup,
  setOpenInventoryAddGroupDialog,
  inventoryAddToGroupSuccess,
  inventoryAddToGroupFail,
  inventoryAddToGroupRequest,
  setSelectedCheckboxes,
  setSelectedGroupToAdd,
  inventoryAddToTagRequest,
  inventoryAddToTagFail,
  inventoryAddToTagSuccess,
  setOpenInventoryAddTagDialog,
  setSelectedTagsToAdd,
  inventoryUpdateStatusSuccess,
  setOpenInventoryUpdateStatusDialog,
  inventoryUpdateStatusFail,
  inventoryUpdateStatusRequest,
  inventoryMergeListingsRequest,
  inventoryMergeListingsFail,
  inventoryMergeListingsSuccess,
  setOpenInventoryMergeListingsDialog,
  setOpenInventoryMergeListingsFailDialog,
  inventoryGetListingDetailsToEditRequest,
  inventoryGetListingDetailsToEditSuccess,
  inventoryGetListingDetailsToEditFail,
  setSelectedListingDetails,
  inventoryEditSelectedItemSuccess,
  inventoryEditSelectedItemFail,
  inventoryEditSelectedItemRequest,
  setBulkActionsListingList,
  setIsRefreshLoading,
  inventoryRemoveGroupsRequest,
  inventoryRemoveTagsRequest,
  inventoryRemoveTagsSuccess,
  inventoryRemoveTagsFail,
  setIsRemoveGroupsDialogOpen,
  inventoryRemoveGroupsSuccess,
  inventoryRemoveGroupsFail,
  setIsRemoveTagsDialogOpen,
  inventoryUpdateTagsRequest,
  inventoryUpdateTagsSuccess,
  inventoryUpdateTagsFail,
  setIsInventoryQuickEditPopoverOpen,
  createUserActionQueueJobFail,
  createUserActionQueueJobSuccess,
  createUserActionQueueJobRequest,
  inventoryImportImagesRequest,
  inventoryImportImagesSuccess,
  inventoryImportImagesFail,
  createListingRequest,
  createListingSuccess,
  createListingFail,
  inventoryUpdateListingContentsRequest,
  inventoryUpdateListingContentsFail,
  inventoryUpdateListingContentsSuccess,
  setCreateListingModeImages,
  setIsEditFormSaveChangesDisabled,
} from '../slices/inventorySlice';

import {
  IEditListingActionPayload,
  IInventoryGroupActionPayload,
  IInventoryTagActionPayload,
  IInventoryDeleteRequestPayload,
  IInventoryMergeListingsRequestPayload,
  IInventoryRequest,
  IInventoryUpdateStatusRequestPayload,
  ISelectedListing,
} from 'src/pages/dashboard/Inventory/types/dataGridInterfaces';
import { toast } from 'react-toastify';
import { IUserActionQueueJobBase } from 'src/pages/dashboard/MyShops/types';
import {
  setImportLoading,
  setInProgressList,
  setIsImportingListings,
  setSelectedImportCheckboxes,
} from '../slices/importTableSlice';
import { ActionSource, ProductType, UserAction } from '@beta.limited/primelister';
import { BackgroundActionsEnum } from 'src/utils/ExtensionMessageChannel';
import { getGroupsRequest, getTagsRequest } from '../slices/groupsAndTagsSlice';
import { CreateNewListingInput } from 'src/pages/dashboard/Inventory/types/newListingInterfaces';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { NavigateFunction } from 'react-router';

function* inventoryGetListSaga(action: PayloadAction<IInventoryRequest>) {
  try {
    if (action.payload.refresh) {
      yield put(setIsRefreshLoading(true));
    }
    const response: AxiosResponse = yield call(() =>
      axiosInstance.get(
        crosslistEndpoints.inventory.GET_INVENTORY(
          action.payload.page,
          action.payload.rowsPerPage,
          action.payload.orderBy,
          action.payload.order,
          action.payload.filter,
          action.payload.searchValue ?? undefined
        )
      )
    );
    yield put(inventoryGetListSuccess(response?.data.data));
    yield put(setInventoryCount(response.data.filteredDataCount));
    yield put(setLoadingEffect(false));
    if (action.payload.refresh) {
      yield put(setIsRefreshLoading(false));
      toast.success('Results successfully refreshed', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  } catch (error) {
    if (action.payload.refresh) {
      yield put(setIsRefreshLoading(false));
    }
    yield put(inventoryGetListFail(error.message));
  }
}

function* inventoryDeleteSaga(action: PayloadAction<IInventoryDeleteRequestPayload>) {
  const deletedItemCount = action.payload.ids.length;
  const plural_singular_listing_word = action.payload.ids.length > 1 ? 'listings' : 'listing';

  const deleteFromInventoryPage = `${deletedItemCount} ${plural_singular_listing_word} deleted from inventory`;
  const deleteFromEditPageMessage = action.payload.deleteMessage;

  const deleteToastMessage = deleteFromEditPageMessage || deleteFromInventoryPage;

  try {
    yield call(() =>
      axiosInstance.post(crosslistEndpoints.inventory.GET_INVENTORY_DELETE(), {
        ids: action.payload.ids,
      })
    );
    yield put(inventoryDeleteSuccess());
    yield put(setDeletePopup(false));
    yield put(
      inventoryGetListRequest({
        page: action.payload.getData.page,
        rowsPerPage: action.payload.getData.rowsPerPage,
        orderBy: action.payload.getData.orderBy,
        order: action.payload.getData.order,
        filter: action.payload.getData.filter,
        searchValue: action.payload.getData.searchValue,
      })
    );
    toast.success(deleteToastMessage, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
    yield put(setSelectedCheckboxes([]));
    yield put(setBulkActionsListingList([]));
  } catch (error) {
    yield put(inventoryDeleteFail(error.message));
  }
}
//-- getImportListings

function* inventoryAddToGroupSaga(action: PayloadAction<IInventoryGroupActionPayload>) {
  const showCustomMessage = action.payload.showCustomMessage ?? true;
  const addToGroupToastMessage = !action.payload.customMessage
    ? `${action.payload.groupName} successfully added to the selection`
    : action.payload.customMessage;

  try {
    yield call(() =>
      axiosInstance.post(crosslistEndpoints.inventory.GET_INVENTORY_ADD_TO_GROUP(), {
        groupId: action.payload.groupId,
        items: action.payload.items,
      })
    );
    yield put(inventoryAddToGroupSuccess());
    yield put(setOpenInventoryAddGroupDialog(false));
    if (action.payload.getData) {
      yield put(
        inventoryGetListRequest({
          page: action.payload.getData.page,
          rowsPerPage: action.payload.getData.rowsPerPage,
          orderBy: action.payload.getData.orderBy,
          order: action.payload.getData.order,
          filter: action.payload.getData.filter,
          searchValue: action.payload.getData.searchValue,
        })
      );
    }
    if (showCustomMessage) {
      toast.success(addToGroupToastMessage, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
    yield put(setSelectedCheckboxes([]));
    yield put(setBulkActionsListingList([]));
    yield put(setSelectedGroupToAdd(''));
    yield put(setIsInventoryQuickEditPopoverOpen(false));
  } catch (error) {
    yield put(inventoryAddToGroupFail(error.message));
    toast.error(error.message, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  }
}

function* inventoryRemoveGroupsSaga(action: PayloadAction<IInventoryGroupActionPayload>) {
  const showCustomMessage = action.payload.showCustomMessage ?? true;
  const addToGroupToastMessage =
    action.payload.customMessage || `All groups from the selected listing(s) removed successfully`;

  try {
    yield call(() =>
      axiosInstance.post(crosslistEndpoints.inventory.GET_INVENTORY_DELETE_GROUP(), {
        items: action.payload.items,
      })
    );
    yield put(inventoryRemoveGroupsSuccess());
    yield put(setIsRemoveGroupsDialogOpen(false));
    if (action.payload.getData) {
      yield put(
        inventoryGetListRequest({
          page: action.payload.getData.page,
          rowsPerPage: action.payload.getData.rowsPerPage,
          orderBy: action.payload.getData.orderBy,
          order: action.payload.getData.order,
          filter: action.payload.getData.filter,
          searchValue: action.payload.getData.searchValue,
        })
      );
    }
    if (showCustomMessage) {
      toast.success(addToGroupToastMessage, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
    yield put(setSelectedCheckboxes([]));
    yield put(setBulkActionsListingList([]));
    yield put(setSelectedGroupToAdd(''));
    yield put(setIsInventoryQuickEditPopoverOpen(false));
  } catch (error) {
    yield put(inventoryRemoveGroupsFail(error.message));
    toast.error(error.message, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  }
}

function* inventoryAddTagsSaga(action: PayloadAction<IInventoryTagActionPayload>) {
  const addTagToastMessage = `Selected tag(s) are successfully added to the listing(s)`;
  try {
    yield call(() =>
      axiosInstance.post(crosslistEndpoints.inventory.GET_INVENTORY_ADD_TO_TAG(), {
        tagIds: action.payload.tagIds,
        items: action.payload.items,
      })
    );
    yield put(inventoryAddToTagSuccess());
    yield put(setOpenInventoryAddTagDialog(false));
    if (action.payload.getData) {
      yield put(
        inventoryGetListRequest({
          page: action.payload.getData.page,
          rowsPerPage: action.payload.getData.rowsPerPage,
          orderBy: action.payload.getData.orderBy,
          order: action.payload.getData.order,
          filter: action.payload.getData.filter,
          searchValue: action.payload.getData.searchValue,
        })
      );
    }
    toast.success(addTagToastMessage, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
    yield put(setSelectedCheckboxes([]));
    yield put(setBulkActionsListingList([]));
    yield put(setSelectedTagsToAdd([]));
  } catch (error) {
    yield put(inventoryAddToTagFail(error.message));
    toast.error(error.message, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  }
}

function* inventoryUpdateTagsSaga(action: PayloadAction<IInventoryTagActionPayload>) {
  const showCustomMessage = action.payload.showCustomMessage ?? true;
  const updateTagToastMessage = !action.payload.customMessage
    ? `${action.payload.tagName} successfully updated`
    : action.payload.customMessage;
  try {
    yield call(() =>
      axiosInstance.post(crosslistEndpoints.inventory.GET_INVENTORY_UPDATE_TAGS(), {
        tagIds: action.payload.tagIds,
        itemId: action.payload.itemId,
      })
    );
    yield put(inventoryUpdateTagsSuccess());
    if (action.payload.getData) {
      yield put(
        inventoryGetListRequest({
          page: action.payload.getData.page,
          rowsPerPage: action.payload.getData.rowsPerPage,
          orderBy: action.payload.getData.orderBy,
          order: action.payload.getData.order,
          filter: action.payload.getData.filter,
          searchValue: action.payload.getData.searchValue,
        })
      );
    }

    if (action.payload.isFromEditPage) {
      yield put(inventoryGetListingDetailsToEditRequest(action.payload.itemId!));
      yield put(getTagsRequest());
      yield put(getGroupsRequest());
    }
    if (showCustomMessage) {
      toast.success(updateTagToastMessage, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
    yield put(setSelectedTagsToAdd([]));
    yield put(setIsInventoryQuickEditPopoverOpen(false));
  } catch (error) {
    yield put(inventoryUpdateTagsFail(error.message));
    toast.error(error.message, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  }
}

function* inventoryRemoveTagsSaga(action: PayloadAction<IInventoryTagActionPayload>) {
  const addTagToastMessage = `Selected tag(s) are successfully removed from the selected listing(s)`;
  try {
    yield call(() =>
      axiosInstance.post(crosslistEndpoints.inventory.GET_INVENTORY_DELETE_TAGS(), {
        tags: action.payload.tagIds,
        items: action.payload.items,
      })
    );
    yield put(inventoryRemoveTagsSuccess());
    yield put(setIsRemoveTagsDialogOpen(false));
    if (action.payload.getData) {
      yield put(
        inventoryGetListRequest({
          page: action.payload.getData.page,
          rowsPerPage: action.payload.getData.rowsPerPage,
          orderBy: action.payload.getData.orderBy,
          order: action.payload.getData.order,
          filter: action.payload.getData.filter,
          searchValue: action.payload.getData.searchValue,
        })
      );
    }
    toast.success(addTagToastMessage, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
    yield put(setSelectedCheckboxes([]));
    yield put(setBulkActionsListingList([]));
    yield put(setSelectedTagsToAdd([]));
  } catch (error) {
    yield put(inventoryRemoveTagsFail(error.message));
    toast.error(error.message, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  }
}

function* inventoryUpdateStatusSaga(action: PayloadAction<IInventoryUpdateStatusRequestPayload>) {
  const updateStatusToastMessage =
    action.payload.items.length > 1
      ? `Listings successfully marked as ${action.payload.status.toLowerCase()}`
      : `Listing successfully marked as ${action.payload.status.toLowerCase()}`;
  try {
    yield call(() =>
      axiosInstance.post(crosslistEndpoints.inventory.GET_INVENTORY_UPDATE_STATUS(), {
        status: action.payload.status,
        items: action.payload.items,
      })
    );
    yield put(inventoryUpdateStatusSuccess());
    yield put(setOpenInventoryUpdateStatusDialog(false));
    yield put(
      inventoryGetListRequest({
        page: action.payload.getData.page,
        rowsPerPage: action.payload.getData.rowsPerPage,
        orderBy: action.payload.getData.orderBy,
        order: action.payload.getData.order,
        filter: action.payload.getData.filter,
        searchValue: action.payload.getData.searchValue,
      })
    );
    toast.success(updateStatusToastMessage, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
    yield put(setSelectedCheckboxes([]));
    yield put(setBulkActionsListingList([]));
    yield put(setSelectedTagsToAdd([]));
  } catch (error) {
    yield put(inventoryUpdateStatusFail(error.message));
    yield put(setOpenInventoryUpdateStatusDialog(false));
    toast.error(error.message, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  }
}
function* inventoryMergeListingsSaga(action: PayloadAction<IInventoryMergeListingsRequestPayload>) {
  const mergeListingsToastMessage = `Selected listings successfully merged`;
  try {
    yield call(() =>
      axiosInstance.post(crosslistEndpoints.inventory.GET_INVENTORY_MERGE_LISTINGS(), {
        items: action.payload.items,
      })
    );
    yield put(inventoryMergeListingsSuccess());
    yield put(setOpenInventoryMergeListingsDialog(false));
    yield put(
      inventoryGetListRequest({
        page: action.payload.getData.page,
        rowsPerPage: action.payload.getData.rowsPerPage,
        orderBy: action.payload.getData.orderBy,
        order: action.payload.getData.order,
        filter: action.payload.getData.filter,
        searchValue: action.payload.getData.searchValue,
      })
    );
    toast.success(mergeListingsToastMessage, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
    yield put(setSelectedCheckboxes([]));
    yield put(setBulkActionsListingList([]));
    yield put(setSelectedTagsToAdd([]));
  } catch (error) {
    yield put(inventoryMergeListingsFail(error.message));
    yield put(setOpenInventoryMergeListingsFailDialog(true));
    yield put(setOpenInventoryMergeListingsDialog(false));
  }
}

function* inventoryGetListingDetailsToEditSaga(action: PayloadAction<string>) {
  try {
    const response: AxiosResponse = yield call(() =>
      axiosInstance.get(
        crosslistEndpoints.inventory.GET_INVENTORY_GET_LISTING_TO_EDIT(action.payload)
      )
    );

    yield put(setSelectedListingDetails(response.data.data));
    yield put(inventoryGetListingDetailsToEditSuccess());
  } catch (error) {
    yield put(inventoryGetListingDetailsToEditFail(error.message));
  }
}
function* inventoryEditSelectedItemSaga(action: PayloadAction<IEditListingActionPayload>) {
  interface IToastSuccessMessages {
    [key: string]: string;
    removeLink: string;
    saveLink: string;
    default: string;
  }

  const toastSuccessMessages: IToastSuccessMessages = {
    removeLink: 'Listing link successfully removed',
    saveLink: 'Listing link successfully saved',
    default: 'Item successfully updated',
  };

  const toastSuccessMessage =
    toastSuccessMessages[action?.payload?.editType!] || toastSuccessMessages.default;

  function* handleNewGroup(editData: IEditListingActionPayload['editData']) {
    const groupResponse: AxiosResponse = yield call(() =>
      axiosInstance.post(crosslistEndpoints.groupsAndTags.GET_GROUPS(), { name: editData.groups })
    );
    editData.groups = groupResponse.data;
  }

  function* handleNewTags(editData: IEditListingActionPayload['editData']) {
    const { listingTags } = editData;
    const newTags = listingTags?.filter((tagInfo) => !tagInfo.id);
    const oldTags = listingTags?.filter((tagInfo) => tagInfo.id) || [];
    const returnedTags: any[] = [];

    yield all(
      newTags!.map(function* (tagInfo) {
        const response: AxiosResponse = yield call(() =>
          axiosInstance.post(crosslistEndpoints.groupsAndTags.GET_TAGS(), tagInfo)
        );
        returnedTags.push(response.data);
      })
    );

    editData.listingTags = [...oldTags, ...returnedTags];
  }

  try {
    const { hasNewGroup, hasNewTag, editData, id } = action.payload;
    if (hasNewGroup) {
      yield call(handleNewGroup, editData);
    }

    if (hasNewTag) {
      yield call(handleNewTags, editData);
    }

    yield delay(0);
    const response: AxiosResponse = yield call(() =>
      axiosInstance.patch(
        crosslistEndpoints.inventory.GET_INVENTORY_GET_LISTING_TO_EDIT(id),
        editData
      )
    );

    yield put(setSelectedListingDetails(response.data.data));
    yield put(inventoryEditSelectedItemSuccess());
    if (action.payload.getData) {
      yield put(
        inventoryGetListRequest({
          page: action.payload.getData.page,
          rowsPerPage: action.payload.getData.rowsPerPage,
          orderBy: action.payload.getData.orderBy,
          order: action.payload.getData.order,
          filter: action.payload.getData.filter,
          searchValue: action.payload.getData.searchValue,
        })
      );
    }

    action.payload.showCustomMessage &&
      toast.success(toastSuccessMessage, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
  } catch (error) {
    yield put(inventoryEditSelectedItemFail(error.message));

    toast.error(error.message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  }
}

function* createUserActionQueueJobSaga(action: PayloadAction<IUserActionQueueJobBase>) {
  try {
    const {
      action: userAction,
      listings,
      marketplace,
      shop,
      selectedImportCheckboxes,
      inProgressList,
    } = action.payload;
    yield put(setIsImportingListings(true));

    const requestPayload = {
      action: userAction,
      marketplace,
      shop: shop.toString(),
      product: ProductType.CROSS_LIST,
      listings,
      actionSource: ActionSource.WEB,
    } as IUserActionQueueJobBase;

    if (userAction === UserAction.IMPORT) {
      yield toast.promise(
        axiosInstance.post(
          crosslistEndpoints.inventory.GET_USER_ACTION_QUEUE_JOB(),
          requestPayload
        ),
        {
          pending: 'Adding importing tasks to queue...',
          success: 'Import tasks added to queue successfully.',
          error: 'Something went wrong',
        },
        { position: 'top-center' }
      );

      yield put(
        setInProgressList([...(inProgressList ?? []), ...(selectedImportCheckboxes ?? [])])
      );
      yield put(setSelectedImportCheckboxes([]));
      yield put(setIsImportingListings(false));
    } else {
      yield call(() =>
        axiosInstance.post(crosslistEndpoints.inventory.GET_USER_ACTION_QUEUE_JOB(), requestPayload)
      );
      yield put(setSelectedCheckboxes([]));
    }

    yield window.extension.sendCommand(BackgroundActionsEnum.UPDATE_ACTIVE_JOBS);
    yield put(createUserActionQueueJobSuccess());
  } catch (error) {
    const { action: userAction } = action.payload;

    toast.error(error.message, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });

    yield put(createUserActionQueueJobFail(error.message));
    yield put(setInProgressList([]));
    yield put(
      userAction === UserAction.IMPORT ? setSelectedImportCheckboxes([]) : setSelectedCheckboxes([])
    );
  }
}

function* handleImportImages(
  action: PayloadAction<{ itemId: string; listingId: string; platform: string }>
) {
  try {
    const { itemId, listingId, platform } = action.payload;

    const response: AxiosResponse = yield call(
      axiosInstance.post,
      crosslistEndpoints.inventory.GET_INVENTORY_IMPORT_IMAGES(listingId),
      {
        marketplace: platform,
        itemId,
      }
    );

    yield put(
      inventoryImportImagesSuccess({
        images: response.data.data.images,
        image: response.data.data.image,
      })
    );
    toast.success('Images imported successfully');
  } catch (error) {
    yield put(inventoryImportImagesFail(error.message));
    toast.error(error.message || 'Failed to import images');
  }
}

function* inventoryUpdateListingContentsSaga(
  action: PayloadAction<{ id: string; contents: FormData }>
): Generator<any, void, any> {
  try {
    const response: AxiosResponse = yield call(() =>
      axiosInstance.post(
        crosslistEndpoints.inventory.GET_INVENTORY_UPDATE_LISTING_CONTENTS(action.payload.id),
        action.payload.contents,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
    );

    const currentState: ISelectedListing = yield select(
      (state: IRootState) => state.inventory.selectedListingDetails
    );

    // If response data is empty array, clear createListingModeImages
    if (Array.isArray(response.data.data) && response.data.data.length === 0) {
      yield put(setCreateListingModeImages([]));
    }

    yield put(
      setSelectedListingDetails({
        ...currentState,
        images: response.data.data,
      })
    );

    yield put(inventoryUpdateListingContentsSuccess());

    toast.success('Listing contents updated successfully', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  } catch (error) {
    yield put(inventoryUpdateListingContentsFail(error.message));
    toast.error(error.message, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  }
}

function* createListingSaga(
  action: PayloadAction<{
    data: CreateNewListingInput;
    contents: File[];
    navigate: NavigateFunction;
  }>
) {
  try {
    const formData = new FormData();

    // Add each file with 'contents' key
    action.payload.contents.forEach((file) => {
      formData.append('contents', file);
    });

    // Add the stringified data
    formData.append('data', JSON.stringify(action.payload.data));

    const response: AxiosResponse = yield call(() =>
      axiosInstance.post(crosslistEndpoints.inventory.GET_CREATE_LISTING(), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    );

    yield put(createListingSuccess());
    action.payload.navigate(PATH_DASHBOARD.inventory.edit.replace(':id', response.data.data._id));
    yield put(setIsEditFormSaveChangesDisabled(true));
    toast.success('Listing created successfully');
  } catch (error: any) {
    yield put(createListingFail(error?.response?.data?.message || 'Failed to create listing'));
    toast.error(error?.response?.data?.message || 'Failed to create listing');
  }
}

export function* inventoryModuleSaga() {
  yield all([
    takeLatest(inventoryGetListRequest.type, inventoryGetListSaga),
    takeLatest(inventoryDeleteRequest.type, inventoryDeleteSaga),
    takeLatest(inventoryAddToGroupRequest.type, inventoryAddToGroupSaga),
    takeLatest(inventoryAddToTagRequest.type, inventoryAddTagsSaga),
    takeLatest(inventoryUpdateStatusRequest.type, inventoryUpdateStatusSaga),
    takeLatest(inventoryMergeListingsRequest.type, inventoryMergeListingsSaga),
    takeLatest(inventoryGetListingDetailsToEditRequest.type, inventoryGetListingDetailsToEditSaga),
    takeLatest(inventoryEditSelectedItemRequest.type, inventoryEditSelectedItemSaga),
    takeLatest(createUserActionQueueJobRequest.type, createUserActionQueueJobSaga),
    takeLatest(inventoryImportImagesRequest.type, handleImportImages),
    takeLatest(inventoryRemoveGroupsRequest.type, inventoryRemoveGroupsSaga),
    takeLatest(inventoryRemoveTagsRequest.type, inventoryRemoveTagsSaga),
    takeLatest(inventoryUpdateTagsRequest.type, inventoryUpdateTagsSaga),
    takeLatest(createListingRequest.type, createListingSaga),
    takeLatest(inventoryUpdateListingContentsRequest.type, inventoryUpdateListingContentsSaga),
  ]);
}
