import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { crosslistEndpoints, poshmarkAutomationEndpoints } from 'src/endpoints';
import axiosInstance from 'src/utils/axios';

import {
  createPriceUpdateJobFail,
  createPriceUpdateJobRequest,
  createPriceUpdateJobSuccess,
  createRelistJobRequest,
  createSendOfferJobFail,
  createSendOfferJobRequest,
  createSendOfferJobSuccess,
  getFilterCategoriesFail,
  getFilterCategoriesRequest,
  getFilterCategoriesSuccess,
  getMyListingsFail,
  getMyListingsRequest,
  getMyListingsSuccess,
  setActiveNewTaskDialogType,
  setIsCreateNewTaskDialogOpen,
  setListingsNextPageId,
  setMyListings,
  setSelectedListings,
  setTotalListingCount,
} from '../slices/myListingsSlice';
import { IListingApiResponse } from 'src/pages/automations/MyListingsPage/types/listing';
import { toast } from 'react-toastify';
import {
  IPriceUpdateActionJob,
  IRelistJob,
  ISendOfferJob,
} from 'src/pages/automations/MyListingsPage/types/bulk-action';
import { ActionSource, ProductType, UserAction } from '@beta.limited/primelister';
import { NewTaskDialogType } from 'src/pages/automations/MyListingsPage/dialogs/CreateNewTaskDialog';
import { IUserActionQueueJobBase } from 'src/pages/dashboard/MyShops/types';
import { IFilterCategory } from 'src/pages/automations/MyListingsPage/components/shared/types/CategoryTreeView';

export interface IGetMyListingsPayload {
  credentialsId: string;
  filters?: {
    count?: number;
    sort?: string;
    inventoryStatus?: string;
    filterForPartyId?: string;
    maxId?: string;
    query?: string;
    maxPrice?: number;
    minPrice?: number;
    maxAge?: number;
    minAge?: number;
    minLikes?: number;
    maxLikes?: number;
  };
}

function* getMyListingsSaga(action: PayloadAction<IGetMyListingsPayload>) {
  try {
    // Check if this is a new search or sort action (not a pagination request)
    if (!action.payload.filters?.maxId) {
      yield put(setMyListings([]));
    }
    const response: AxiosResponse<IListingApiResponse> = yield call(() =>
      axiosInstance.get(
        poshmarkAutomationEndpoints.listings.GET_MY_LISTINGS({
          ...action.payload,
          filters: {
            ...action.payload.filters,
            count: 100 || action.payload.filters?.count,
          },
        })
      )
    );

    if (response.data.nextMaxId) {
      yield put(setListingsNextPageId(response.data.nextMaxId));
    } else {
      // This is a fix to reset the nextPageId when the search query changes
      yield put(setListingsNextPageId(''));
    }

    yield put(setTotalListingCount(response.data.totalListingCount));
    yield put(getMyListingsSuccess(response.data.listings?.length ? response.data.listings : []));
  } catch (error) {
    // Handle 520 timeout error
    if (!error.message) {
      toast.error(
        'You have too many listings, making it difficult for us to fetch them all. Please reduce the number of results by applying a filter.',
        {
          position: 'top-right',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        }
      );
    }
    yield put(getMyListingsFail(error.message ? error.message : 'Something went wrong'));
  }
}

/**
 * Saga to handle job actions such as sending offers, updating prices, or relisting items.
 *
 * @template T - The type of job action, which extends ISendOfferJob, IPriceUpdateActionJob, or IRelistJob.
 * @param {PayloadAction<T>} action - The action payload containing job details.
 * @param {string} jobType - The type of job being processed (e.g., 'OFFER', 'UPDATE').
 * @param {() => PayloadAction<void>} successAction - The action to dispatch on successful job creation.
 * @param {(error: string) => PayloadAction<string>} failAction - The action to dispatch on job creation failure.
 * @param {boolean} isMobile - Flag indicating if the request is made from a mobile device.
 */
function* handleJobSaga<T extends ISendOfferJob | IPriceUpdateActionJob | IRelistJob>(
  action: PayloadAction<T>,
  jobType: string,
  successAction: () => PayloadAction<void>,
  failAction: (error: string) => PayloadAction<string>,
  isMobile: boolean
) {
  try {
    const requestPayload = {
      action: action.payload.action,
      listings: action.payload.listings,
      marketplace: action.payload.marketplace,
      actionSource: ActionSource.WEB,
      shop: action.payload.shop,
      product: ProductType.POSHMARK_AUTOMATION,
      ...(action.payload.targetTime && { targetTime: action.payload.targetTime }),
      ...(jobType === UserAction.OFFER && {
        sendOffer: { settings: (action.payload as ISendOfferJob).sendOffer.settings },
      }),
      ...(jobType === UserAction.UPDATE && {
        priceUpdate: (action.payload as IPriceUpdateActionJob).priceUpdate,
      }),
    };

    yield toast.promise(
      axiosInstance.post(crosslistEndpoints.inventory.GET_USER_ACTION_QUEUE_JOB(), requestPayload),
      {
        pending: `Adding ${jobType} tasks to queue..`,
        success: 'Your tasks successfully created',
        error: 'Something went wrong',
      },
      { position: !isMobile ? 'top-right' : 'bottom-center' }
    );

    yield put(successAction());
    yield put(setActiveNewTaskDialogType(NewTaskDialogType.NONE));
  } catch (error) {
    yield put(failAction(error.message));
  }
}

function* createSendOfferJobSaga(action: PayloadAction<ISendOfferJob & { isMobile: boolean }>) {
  yield handleJobSaga<ISendOfferJob>(
    action,
    action.payload.action,
    createSendOfferJobSuccess,
    createSendOfferJobFail,
    action.payload.isMobile
  );
}

function* createRelistJobSaga(action: PayloadAction<IRelistJob & { isMobile: boolean }>) {
  yield handleJobSaga<IRelistJob>(
    action,
    action.payload.action,
    createSendOfferJobSuccess,
    createSendOfferJobFail,
    action.payload.isMobile
  );
}

function* createPriceUpdateJobSaga(
  action: PayloadAction<IPriceUpdateActionJob & { isMobile: boolean }>
) {
  yield handleJobSaga<IPriceUpdateActionJob>(
    action,
    action.payload.action,
    createPriceUpdateJobSuccess,
    createPriceUpdateJobFail,
    action.payload.isMobile
  );
}

function* getFilterCategoriesSaga(
  action: PayloadAction<{
    credentialsId: string;
    department?: string;
    category?: string;
  }>
) {
  try {
    const response: AxiosResponse<IFilterCategory[]> = yield call(() =>
      axiosInstance.get(
        poshmarkAutomationEndpoints.listings.GET_FILTER_CATEGORIES(
          action.payload.credentialsId,
          action.payload.department,
          action.payload.category
        )
      )
    );

    yield put(getFilterCategoriesSuccess(response.data));
  } catch (error) {
    yield put(getFilterCategoriesFail(error.message));
  }
}

export function* myListingsModuleSaga() {
  yield takeLatest(getMyListingsRequest.type, getMyListingsSaga);
  yield takeLatest(createSendOfferJobRequest.type, createSendOfferJobSaga);
  yield takeLatest(createPriceUpdateJobRequest.type, createPriceUpdateJobSaga);
  yield takeLatest(createRelistJobRequest.type, createRelistJobSaga);
  yield takeLatest(getFilterCategoriesRequest.type, getFilterCategoriesSaga);
}
