import {
  Container,
  Stack,
  Typography,
  Box,
  FormHelperText,
  Stepper,
  Step,
  StepLabel,
  styled,
  Skeleton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Helmet } from 'react-helmet-async';
import CreateListingForm from './components/CreateListingForm';
import PlatfromLinksContainer, {
  PlatformLinksMode,
} from '../EditPage/components/PlatformLinksContainer';
import { useState, useCallback, memo, useTransition, useEffect, useMemo } from 'react';
import { Marketplace } from '@beta.limited/primelister';
import { getMarketplaceColor } from 'src/pages/dashboard/Tasks/helpers/getMarketplaceColor';
import Image from 'src/components/image';
import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from 'src/store';
import {
  clearCreateModeLinks,
  createListingRequest,
  clearCreateListingModeImages,
} from 'src/store/dashboard/slices/inventorySlice';
import { getGroupsRequest, getTagsRequest } from 'src/store/dashboard/slices/groupsAndTagsSlice';
import { ListingStatus } from '../../types/newListingInterfaces';
import { useNavigate } from 'react-router';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import DoneIcon from '@mui/icons-material/Done';
import theme from 'src/theme';
import { useUnsavedChangesWarning } from 'src/hooks/useUnsavedChangesWarning';
import { toast } from 'react-toastify';

const MARKETPLACE_TABS = [
  { label: 'eBay', value: 'ebay' },
  { label: 'Poshmark', value: 'poshmark' },
  { label: 'Mercari', value: Marketplace.MERCARI },
  { label: 'Depop', value: Marketplace.DEPOP },
  { label: 'Etsy', value: Marketplace.ETSY },
  { label: 'Facebook', value: Marketplace.FACEBOOK },
  { label: 'Shopify', value: Marketplace.SHOPIFY },
  { label: 'Grailed', value: Marketplace.GRAILED },
];

const MemoizedCreateListingForm = memo(CreateListingForm);
const MemoizedPlatformLinksContainer = memo(PlatfromLinksContainer);

const CustomStepLabel = styled(StepLabel)(({ theme }) => ({
  '& .MuiStepLabel-label': {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
    '&.Mui-active': {
      color: theme.palette.primary.main,
    },
    '&.Mui-completed': {
      color: theme.palette.success.main,
    },
  },
  '& .MuiStepLabel-iconContainer': {
    paddingRight: theme.spacing(1),
  },
}));

const StepDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '0.75rem',
  marginTop: 2,
  textAlign: 'center',
  width: '100%',
  position: 'absolute',
  left: 0,
  top: '80%',
}));

const CustomStep = styled(Step)({
  '& .MuiStepLabel-root': {
    flexDirection: 'column',
  },
  position: 'relative',
  paddingBottom: '20px',
});

const DashedCircle = styled(Box)<{ completed: boolean }>(({ theme, completed }) => ({
  width: 20,
  height: 20,
  borderRadius: '50%',
  border: `1.5px ${completed ? 'solid' : 'dashed'} ${
    completed ? theme.palette.success.main : theme.palette.text.secondary
  }`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& svg': {
    fontSize: 14,
    color: completed ? theme.palette.success.main : theme.palette.text.secondary,
  },
}));

function CreatePage() {
  const [activeTab, setActiveTab] = useState(0);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isPending, startTransition] = useTransition();
  const [formData, setFormData] = useState<any>(null);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { createModeLinks, createListingModeImages, createListingLoading } = useSelector(
    (state: IRootState) => state.inventory
  );

  const isButtonsDisabled = useMemo(() => {
    const hasValidLink = Object.values(createModeLinks).some((link) => link.isValid);
    const isTitleEmpty = !formData?.title?.trim();

    return {
      draft: isTitleEmpty,
      create: isTitleEmpty || !hasValidLink,
    };
  }, [createModeLinks, formData?.title]);

  useEffect(() => {
    dispatch(getTagsRequest());
    dispatch(getGroupsRequest());
  }, []);

  const handleTabChange = useCallback((index: number) => {
    startTransition(() => {
      setActiveTab(index);
    });
  }, []);

  const handleFormChange = useCallback((isDirty: boolean, data: any) => {
    setIsFormDirty(isDirty);
    setFormData(data);
  }, []);

  useUnsavedChangesWarning({ hasUnsavedChanges: isFormDirty });

  const handleSubmit = (isDraft: boolean = false) => {
    if (!formData) return;

    // Check if there are only videos and no images
    const hasOnlyVideo =
      createListingModeImages.length > 0 &&
      createListingModeImages.every((img) => img.type === 'video');

    if (hasOnlyVideo) {
      toast.error('At least one image is required to create a listing', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      return;
    }

    // Convert createModeLinks to refs array
    const refs = Object.entries(createModeLinks).map(([marketplace, link]) => ({
      marketplace: marketplace as Marketplace,
      reference: {
        id: link.id,
        listingLink: link.listingLink,
        platform: marketplace,
        added: link.added,
        updated: link.updated,
        closetName: link.closetName,
      },
    }));

    // Get and sort files from createListingModeImages
    // Images will be first, MP4 files will be at the end
    const getContentsWithSortedMP4Files = () => {
      const filesFromImages = createListingModeImages
        .filter((image) => image.file)
        .map((image) => image.file as File);

      // Sort files - MP4 files should be at the end
      return filesFromImages.sort((firstFile, secondFile) => {
        const isFirstFileMP4 = firstFile.type === 'video/mp4';
        const isSecondFileMP4 = secondFile.type === 'video/mp4';

        // If first file is MP4 and second is not, move first file to end
        if (isFirstFileMP4 && !isSecondFileMP4) {
          return 1;
        }

        // If first file is not MP4 and second is MP4, keep first file at beginning
        if (!isFirstFileMP4 && isSecondFileMP4) {
          return -1;
        }

        // If both files are MP4 or both are not MP4, keep original order
        return 0;
      });
    };

    const contents = getContentsWithSortedMP4Files();

    // Restructure the data to match CreateNewListingInput interface
    const { listingTags, groups, ...restData } = formData;

    const payload = {
      data: {
        ...restData,
        ...(listingTags?.length && { tags: listingTags.map((tag: any) => tag._id) }),
        ...(groups?.id && { groupId: groups.id }),
        status: isDraft ? ListingStatus.Draft : formData.status || ListingStatus.Listed,
        refs,
      },
      contents,
      navigate,
    };

    dispatch(createListingRequest(payload));
    dispatch(clearCreateModeLinks());
    dispatch(clearCreateListingModeImages());
  };

  const isStep1Complete = useMemo(() => !!formData?.title?.trim(), [formData?.title]);

  const isStep2Complete = useMemo(
    () => Object.values(createModeLinks).some((link) => link.isValid),
    [createModeLinks]
  );

  const steps = [
    {
      label: 'Draft',
      description: isStep1Complete ? 'Ready to save as draft' : 'Add a title to save as draft',
      completed: isStep1Complete,
    },
    {
      label: 'Create',
      description: isStep2Complete
        ? 'Ready to create listing'
        : 'Add a listing link to create listing',
      completed: isStep2Complete,
    },
  ];

  const activeStep = useMemo(() => {
    if (!isStep1Complete) return 0;
    if (!isStep2Complete) return 1;
    return 2;
  }, [isStep1Complete, isStep2Complete]);

  return (
    <>
      <Helmet>
        <title>Create New Listing | PrimeLister</title>
      </Helmet>
      <Container maxWidth={'lg'} disableGutters={true}>
        <Stack spacing={2} mb={3}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h4">Create New Listing</Typography>
            <Stack direction="row" spacing={2}>
              <LoadingButton
                variant="outlined"
                onClick={() => handleSubmit(true)}
                loading={createListingLoading}
                disabled={isButtonsDisabled.draft}
                sx={{ textTransform: 'none' }}
              >
                Save as Draft
              </LoadingButton>
              <LoadingButton
                variant="contained"
                onClick={() => handleSubmit(false)}
                loading={createListingLoading}
                disabled={isButtonsDisabled.create}
              >
                Create Listing
              </LoadingButton>
            </Stack>
          </Stack>
        </Stack>

        <>
          <Stack spacing={3}>
            {/* List Stepper */}
            {createListingLoading ? (
              <Skeleton variant="rounded" sx={{ width: '100', height: '3rem' }} />
            ) : (
              <Stack spacing={1}>
                {steps.map((step, index) => (
                  <Stack
                    key={step.label}
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    sx={{
                      color: step.completed
                        ? 'success.main'
                        : index === activeStep
                        ? 'primary.main'
                        : 'text.secondary',
                    }}
                  >
                    <DashedCircle completed={step.completed}>
                      {step.completed ? <DoneIcon /> : null}
                    </DashedCircle>
                    <Typography
                      variant="body2"
                      color={step.completed ? 'success.main' : 'text.secondary'}
                    >
                      {step.description}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            )}

            <Stack spacing={1}>
              <Box sx={{ width: '100%' }}>
                <MemoizedCreateListingForm
                  marketplace={MARKETPLACE_TABS[0].value as Marketplace}
                  onFormChange={handleFormChange}
                />
              </Box>
            </Stack>

            <Stack>
              <Stack direction="row" sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2 }}>
                <Typography variant="h6">Listing Links</Typography>
              </Stack>
              <Typography variant="body1" color="text.secondary" sx={{ mb: 3, fontSize: '13px' }}>
                Add at least one listing link to create your listing
              </Typography>
              <MemoizedPlatformLinksContainer mode={PlatformLinksMode.CREATE} />
            </Stack>
          </Stack>
        </>
      </Container>
    </>
  );
}

export default CreatePage;
